import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { HttpIntercepterService } from "src/app/shared/services/http-intercepter.service";
import { environment } from "src/environments/environment";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Component({
  selector: "app-public-profile",
  templateUrl: "./public-profile.component.html",
  styleUrls: ["./public-profile.component.scss"]
})
export class PublicProfileComponent implements OnInit {
  private _baseURL = environment.url;
  isgotData: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpIntercepterService,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }
  userName: string;
  userProfileList: any = [];
  profileMdl: any = {};
  domChanges: any = {};
  profileBuilder: any = {};
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.userName = params.userName;
    });

    if (this.userName) {
      this.spinnerService.show();
      this.getUserProfile();
    }
  }
  getUserProfile() {
    this.http
      .getDataFromServer(
        this._baseURL +
        "/profile/getProfileByUserName?username=" +
        `${this.userName}`
      )
      .subscribe(res => {
        this.spinnerService.hide();
        if (res.data && res.data.length) {
          this.isgotData = true;
          this.userProfileList = res.data[0];
          // this.userProfileList._projectLists.sort((a, b) => {
          //   if (new Date(b.from) != new Date(a.from)) {
          //     return <any>new Date(b.from) - <any>new Date(a.from);
          //   }else if(a.to && b.to){
          //     return <any>new Date(b.to) - <any>new Date(a.to);
          //   }
          // });
          // this.userProfileList._empHistory.sort((a, b) => {
          //   if (new Date(b.from) != new Date(a.from)) {
          //     return <any>new Date(b.from) - <any>new Date(a.from);
          //   }else if(a.to && b.to){
          //     return <any>new Date(b.to) - <any>new Date(a.to);
          //   }
          // });
          this.domChanges.datesList1 = [];
          this.domChanges.datesList2 = [];
          this.domChanges.empHisDateList1 = [];
          this.domChanges.empHisDateList2 = [];

          /// Logic For Sort Object 
          for (var k = 0; k < this.userProfileList._projectLists.length; k++) {
            if (this.userProfileList._projectLists[k].to) {
              this.domChanges.datesList1.push(this.userProfileList._projectLists[k])
            } else {
              this.domChanges.datesList2.push(this.userProfileList._projectLists[k])
            }
          }
          this.domChanges.datesList1 = this.sortDates(this.domChanges.datesList1, false);
          this.domChanges.datesList2 = this.sortDates(this.domChanges.datesList2, true);
          this.userProfileList._projectLists = this.domChanges.datesList2;
          for (var p = 0; p < this.domChanges.datesList1.length; p++) {
            this.userProfileList._projectLists[this.userProfileList._projectLists.length] = this.domChanges.datesList1[p]
          }

          /// Logic For Sort Object EMP HIS
          for (var k = 0; k < this.userProfileList._empHistory.length; k++) {
            if (this.userProfileList._empHistory[k].to) {
              this.domChanges.empHisDateList1.push(this.userProfileList._empHistory[k])
            } else {
              this.domChanges.empHisDateList2.push(this.userProfileList._empHistory[k])
            }
          }
          this.domChanges.empHisDateList1 = this.sortDates(this.domChanges.empHisDateList1, false);
          this.domChanges.empHisDateList2 = this.sortDates(this.domChanges.empHisDateList2, true);
          this.userProfileList._empHistory = this.domChanges.empHisDateList2;
          for (var p = 0; p < this.domChanges.empHisDateList1.length; p++) {
            this.userProfileList._empHistory[this.userProfileList._empHistory.length] = this.domChanges.empHisDateList1[p]
          }
        }
      });
  }
  sortDates(item, to) {
    if (to) {
      return item.sort((a, b) => {
        if (new Date(b.from) != new Date(a.from)) {
          return <any>new Date(b.from) - <any>new Date(a.from);
        }
      });
    } else {
      return item.sort((a, b) => {
        if (new Date(b.from) != new Date(a.from)) {
          return <any>new Date(b.from) - <any>new Date(a.from);
        } else {
          return <any>new Date(a.to) - <any>new Date(b.to);
        }
      });
    }
  }
}
