import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpIntercepterService } from 'src/app/shared/services/http-intercepter.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-creation',
  templateUrl: './company-creation.component.html',
  styleUrls: ['./company-creation.component.scss']
})
export class CompanyCreationComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService, private http: HttpIntercepterService, private route: Router) { }
  domChanges: any = {
    areaOfExpEdit: false
  };
  cmpyDetails: any = {};
  private auth = localStorage.getItem('myToken');
  private id = localStorage.getItem('id');
  companyMdl: any = {
    cmpDesc: 'Click here to add about your company',
  };
  areaOfExpMdl: any = {}
  teamMdl: any = {}
  teamList: any = [];
  areaOfExpList: any = [];
  fileName: string;
  filePreview: string
  private _baseURL = environment.url;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
  ngOnInit() {
    this.getCmpDetail();
  }
  onFileChanged(event: any, type: any) {
    let reader: any = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileName = file.name + " " + file.type;
        this.filePreview = 'data:image/png' + ';base64,' + reader.result.split(',')[1];
        if (type == 'area') {
          this.areaOfExpMdl.image = this.filePreview;
        } else if (type == 'team') {
          this.teamMdl.image = this.filePreview;
        }
      };
    }
  }
  getCmpDetail() {
    this.spinnerService.show();
    this.http.getDataFromServer(this._baseURL + '/Accounts/' + `${localStorage.getItem('id')}/company?access_token=${this.auth}`).subscribe(
      res => {
        this.spinnerService.hide();
        if (res.id) {
          this.cmpyDetails = res;
          this.companyMdl.cmpDesc = res.cmpDesc ? res.cmpDesc : 'Click here to add about your company';
          this.areaOfExpList = res._areaOfExpertise;
          this.teamList = res._team;
        }
      })
  }
  saveorUpdateCmpDesc() {
    this.spinnerService.show();
    this.companyMdl.accountId = this.id;
    if (!this.cmpyDetails.id) {
      this.http.postDataToserver(this._baseURL + '/Companies' + `?access_token=${this.auth}`, this.companyMdl).subscribe(
        res => {
          this.getCmpDetail();
          this.spinnerService.hide();
          this.resetDOM();
        })
    } else {
      if (!this.companyMdl._isDeleted) {
        this.companyMdl._isDeleted = false;
      }
      this.http.updateDataToServer(this._baseURL + '/Companies/' + `${this.cmpyDetails.id}?access_token=${this.auth}`, this.companyMdl).subscribe(
        res => {
          this.getCmpDetail();
          this.spinnerService.hide();
          this.resetDOM();
        })
    }
  }
  /// Area Of Expertise ///
  addorUpdateArea() {
    this.spinnerService.show();
    if (this.cmpyDetails.id) {
      if (!this.domChanges.areaOfExpEdit) {
        this.http.postDataToserver(this._baseURL + '/Companies/' + `${this.cmpyDetails.id}/areaOfExpertise?access_token=${this.auth}`, this.areaOfExpMdl).subscribe(
          res => {
            this.getCmpDetail();
            this.areaOfExpMdl = {};
            this.spinnerService.hide();
            this.resetDOM();
            this.domChanges.areaOfExpEdit = false;
            this.domChanges.areaOfExpClicked = false;
          })
      } else {
        this.http.nestedObjectsUpdate(this._baseURL + '/Companies/' + `${this.cmpyDetails.id}/areaOfExpertise/${this.areaOfExpMdl.id}?access_token=${this.auth}`, this.areaOfExpMdl).subscribe(
          res => {
            this.areaOfExpMdl = {};
            this.getCmpDetail();
            this.spinnerService.hide();
            this.resetDOM();
            this.domChanges.areaOfExpEdit = false;
            this.domChanges.areaOfExpClicked = false;
          })
      }
    } else {
      this.http.nestedObjectsUpdate(this._baseURL + '/Companies' + `?access_token=${this.auth}`, { _areaOfExpertise: [this.areaOfExpMdl] }).subscribe(
        res => {
          this.getCmpDetail();
          this.resetDOM()
          this.spinnerService.hide();
        })
    }
  }
  editAreaOfExp(item: any) {
    this.areaOfExpMdl = Object.assign({}, item);
    this.filePreview = this.areaOfExpMdl.image;
    this.domChanges.areaOfExpEdit = true;
    this.domChanges.areaOfExpClicked = true;
  }
  deleteareaOfExp(item: any, ind: number) {
    if (confirm('Are you sure,do you want to delete  ' + item.name)) {
      this.spinnerService.show();
      this.http.deleteDataFromServer(this._baseURL + '/Companies/' + `${this.cmpyDetails.id}/areaOfExpertise/${item.id}?access_token=${this.auth}`).subscribe(
        res => {
          this.spinnerService.hide();
          this.areaOfExpList.splice(ind, 1);
        })
    }
  }
  /// End ///

  /// Management Team///
  addorUpdateTeam() {
    this.spinnerService.show();
    if (this.cmpyDetails.id) {
      if (!this.domChanges.teamEdit) {
        this.http.postDataToserver(this._baseURL + '/Companies/' + `${this.cmpyDetails.id}/team?access_token=${this.auth}`, this.teamMdl).subscribe(
          res => {
            this.getCmpDetail();
            this.teamMdl = {};
            this.spinnerService.hide();
            this.resetDOM();
            this.domChanges.teamEdit = false;
            this.domChanges.managementClicked = false;
          })
      } else {
        this.http.nestedObjectsUpdate(this._baseURL + '/Companies/' + `${this.cmpyDetails.id}/team/${this.teamMdl.id}?access_token=${this.auth}`, this.teamMdl).subscribe(
          res => {
            this.teamMdl = {};
            this.getCmpDetail();
            this.spinnerService.hide();
            this.resetDOM();
            this.domChanges.teamEdit = false;
            this.domChanges.managementClicked = false;
          })
      }
    } else {
      this.http.nestedObjectsUpdate(this._baseURL + '/Companies' + `?access_token=${this.auth}`, { _team: [this.teamMdl] }).subscribe(
        res => {
          this.getCmpDetail();
          this.resetDOM()
          this.spinnerService.hide();
        })
    }
  }
  editAreaOfTeam(item: any) {
    this.teamMdl = Object.assign({}, item);
    this.filePreview = this.teamMdl.image;
    this.domChanges.teamEdit = true;
    this.domChanges.managementClicked = true;
  }
  deleteTeam(item: any, ind: number) {
    if (confirm('Are you sure,do you want to delete  ' + item.name)) {
      this.spinnerService.show();
      this.http.deleteDataFromServer(this._baseURL + '/Companies/' + `${this.cmpyDetails.id}/team/${item.id}?access_token=${this.auth}`).subscribe(
        res => {
          this.spinnerService.hide();
          this.teamList.splice(ind, 1);
        })
    }
  }
  //// End////
  resetDOM() {
    this.domChanges = {};
    this.areaOfExpMdl = {};
    this.teamMdl = {};
    this.fileName = '';
    this.filePreview = '';
  }


}
