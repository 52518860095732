<div class="page-body">

  <!--profile cover start-->
  <div class="row">
    <div class="col-lg-12">
      <p class="text-success">Manage your Hasman Connections here</p>
      <textarea class="form-control" name="commaEmails" rows="4" cols="50" placeholder="Enter Email addresses here,  Separated by comma"
        [(ngModel)]="domChanges.commaEmails" #commaEmails="ngModel" required></textarea><br>
      <button type="button" class="btn btn-primary btn-round pull-right" (click)="sendEmailInvitaions()">Send
        Invitation
      </button>
    </div>
    <br><br>
  </div>
  <div class="row" style="margin-top:2%">
    <div class="col-lg-12">
      <div class="md-tabs md-card-tabs">
        <ngb-tabset>
          <ngb-tab title="My Connections">
            <ng-template ngbTabContent>
              <div class="m-t-15">
                <div class="row page-header card" *ngIf="myConncetionsList.length<1">
                  <div class="card-block ">
                    <p class="text-warning text-center">You don't have any connections right now</p>
                  </div>
                </div>
                <div class="row users-card" *ngIf="myConncetionsList.length>0">
                  <div class="col-lg-6 col-xl-3 col-md-6" *ngFor="let item of myConncetionsList;let i =index">
                    <div class="card rounded-card user-card">
                      <div class="card-block" style="height: 240px;overflow: auto;">
                        <div class="img-hover">
                          <img *ngIf="item.sent && item?.accepted?.profilePic" class="img-radius" [src]="item?.accepted?.profilePic | safeImgUrl" alt="bg-img">
                          <img *ngIf="!item.sent && item?.account?.profilePic" class="img-radius" [src]="item?.account?.profilePic | safeImgUrl" alt="bg-img">
                          <img *ngIf="item.sent && !item.accepted?.profilePic" class="img-radius" src="assets/images/noImage.png" alt="bg-img">
                          <img *ngIf="!item.sent && !item.account?.profilePic" class="img-radius" src="assets/images/noImage.png" alt="bg-img">
                        </div>
                        <div class="user-content">
                          <p class="text-primary user-content-p">{{item.sent ? (item.accepted.firstName+' '+item.accepted.lastName):(item.account.firstName+' '+item.account.lastName)}}
                          </p>
                          <span class="text-success">{{item.sent ? (item.accepted.email):(item.account.email)}}
                          </span><br>
                          <span class="text-dark small">{{item.sent ? (item.accepted.mobile ? '+'+item.accepted.mobile:''):(item.account.mobile ? '+'+item.account.mobile:'')}}
                          </span><br><br>
                          <a style="color:white" placement="bottom" ngbTooltip="Do you want to View Profile ? "
                          (click)="viewProfile(item)" class="btn btn-success btn-round ">View Profile</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Sent Invitations">
            <ng-template ngbTabContent>
              <div class="m-t-15">
                <div class="row page-header card" *ngIf="sentInvitationList.length<1">
                  <div class="card-block ">
                    <p class="text-warning text-center">You're not sent any invitations right now</p>
                  </div>
                </div>
                <div class="row users-card" *ngIf="sentInvitationList.length>0">
                  <div class="col-lg-6 col-xl-3 col-md-6" *ngFor="let item of sentInvitationList;let i =index">
                    <div class="card rounded-card user-card">
                      <div class="card-block" style="height: 240px;overflow: auto;">
                        <div class="img-hover">
                          <img *ngIf="item.image" class="img-radius" [src]="item.image | safeImgUrl" alt="bg-img">
                          <img *ngIf="!item.image" class="img-radius" src="assets/images/noImage.png" alt="bg-img">
                        </div>
                        <div class="user-content">
                          <p class="text-success">{{item.name}}
                          </p>
                          <p class="text-success user-content-p">{{item.email}}
                          </p>
                          <span class="text-primary small">{{item.sentDate ? (item.sentDate | date:'dd-MMM-yyyy hh:mm
                            a'):''}}
                          </span><br><br>
                          <a *ngIf="!item.status" style="color:white" placement="bottom" ngbTooltip="Do you want to re-send the invitation ? "
                            (click)="resendInvitation(item)" class="btn btn-primary btn-round">resend</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Invitation Requests">
            <ng-template ngbTabContent>
              <div class="m-t-15">
                <div class="row page-header card" *ngIf="requestToMeList.length<1">
                  <div class="card-block ">
                    <p class="text-warning text-center">You don't have any requests right now</p>
                  </div>
                </div>
                <div class="row users-card" *ngIf="requestToMeList.length>0">
                    <div class="col-lg-6 col-xl-3 col-md-6" *ngFor="let item of requestToMeList;let i =index">
                      <div class="card rounded-card user-card">
                        <div class="card-block" style="height: 240px;overflow: auto;">
                          <div class="img-hover">
                            <img *ngIf="item?.account?.profilePic" class="img-radius" [src]="item?.account?.profilePic | safeImgUrl" alt="bg-img">
                            <img *ngIf="!item.account?.profilePic" class="img-radius" src="assets/images/noImage.png" alt="bg-img">
                          </div>
                          <div class="user-content">
                            <p class="text-primary user-content-p">{{item.account.firstName+' '+item.account.lastName}}
                            </p>
                            <span class="text-success" *ngIf="item.account?.email">{{item.account.email}}
                            </span>
                            <span class="text-success" *ngIf="!item.account?.email">{{item.email}}
                            </span>
                            <span class="text-primary small" *ngIf="!item.account?.email">{{item.sentDate ? (item.sentDate | date:'dd-MMM-yyyy hh:mm
                              </span>
                            <span class="text-dark small" *ngIf="item.account?.mobile">{{item.account.mobile ? '+'+item.account.mobile:''}}
                            </span>
                            <br><br>
                            <a  style="color:white;padding:6px !important" placement="bottom" ngbTooltip="Do you want to Accept the invitation ? "
                            (click)="acceptOrRejectInv(item,'accept')" class="btn btn-success btn-round">Accept</a>&nbsp;&nbsp;
                          <a  style="color:white;padding:6px !important" placement="bottom" ngbTooltip="Do you want to reject the invitation ? "
                            (click)="acceptOrRejectInv(item,'reject')" class="btn btn-danger btn-round">Reject</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</div>