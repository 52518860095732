import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  params: string,
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}


@Injectable()
export class MenuItems {
  checkAdmin: string;
  menuList: any = [];
  MENUITEMS = [];
  getAll(): Menu[] {
    this.MENUITEMS = [
      {
        label: 'profile',
        main: [
          {
            state: 'profile',
            main_state: 'dashboard',
            short_label: 'D',
            name: 'Profile',
            type: 'link',
            icon: 'ti-user',
          },
          {
            state: 'connections',
            main_state: 'dashboard',
            params: 'direct',
            short_label: 'C',
            name: 'Connections',
            type: 'params',
            icon: 'ti-world',
          },
          {
            state: 'skillSets',
            main_state: 'admin',
            short_label: 'S',
            name: 'Skills',
            type: 'link',
            icon: 'ti-settings',
          },
        ],
      }
    ];
    this.menuList = this.MENUITEMS;
    this.checkAdmin = localStorage.getItem('redirect');
    if (this.checkAdmin != "ad") {
      for (let k = 0; k < this.menuList[0].main.length; k++) {
        if (this.menuList[0].main[k].main_state == 'admin') {
          this.menuList[0].main.splice(k, 1);
        }
      }
    }
    return this.menuList;
  }
}
