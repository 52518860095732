import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpIntercepterService } from 'src/app/shared/services/http-intercepter.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private http: HttpIntercepterService, private route: Router, public toastr: ToastrManager) { }
  model: any = {};
  resetPass: any;
  passwordPattren = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&_#]){8,}");
  pwdpattern = true;
  domChanges: any = {};
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.model.token = params.access_token;
    });
    this.domChanges.passwordType = 'password';
    this.domChanges.passwordIcon = 'icofont icofont-eye-blocked'
    this.domChanges.cnfpasswordType = 'password';
    this.domChanges.cnfpasswordIcon = 'icofont icofont-eye-blocked'
  }
  resetPassword() {
    if (this.model.newPassword != this.domChanges.cnfPassword) {
      alert('Password Does not Match');
      return;
    }
    this.http.resetPassword('Accounts/reset-password', this.model).subscribe(res => {
      localStorage.clear();
      this.resetPass = true;
      setTimeout(() => {
        this.domChanges.cnfPassword='';
        this.route.navigate(['/login/direct']);
      }, 5000);

    },
      error => {
        if (error._body) {
          let err = JSON.parse(error._body);
          this.toastr.errorToastr(err.error.message, 'Oops!');
        } else {
          this.toastr.errorToastr("Some thing went Wrong with Server, Please Try agin", 'Oops!');
        }
      }
    )
  }
  checkValidation(ev) {
    if (this.passwordPattren.test(ev) == true) {
      this.pwdpattern = true;
    } else {
      this.pwdpattern = false;
    }
  }
  showHidePassword(type: any) {
    if (type == 'cnf') {
      if (this.domChanges.cnfpasswordType == 'password') {
        this.domChanges.cnfpasswordType = 'text';
        this.domChanges.cnfpasswordIcon = 'icofont icofont-eye';
      } else {
        this.domChanges.cnfpasswordType = 'password';
        this.domChanges.cnfpasswordIcon = 'icofont icofont-eye-blocked';
      }
    } else {
      if (this.domChanges.passwordType == 'password') {
        this.domChanges.passwordType = 'text';
        this.domChanges.passwordIcon = 'icofont icofont-eye';
      } else {
        this.domChanges.passwordType = 'password';
        this.domChanges.passwordIcon = 'icofont icofont-eye-blocked';
      }
    }
  }

}
