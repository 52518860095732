import * as i0 from "@angular/core";
var ProfileService = /** @class */ (function () {
    function ProfileService() {
    }
    ProfileService.prototype.setProfile = function (value) {
        this._profile = value;
    };
    ProfileService.prototype.getProfile = function () {
        return this._profile;
    };
    ProfileService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
