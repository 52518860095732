<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="signup-card card-block auth-body mr-auto ml-auto">
          <div class="auth-box">
            <div class="text-center">
              <img src="assets/images/hasmanlogo.svg" alt="Hasman img">
            </div>
            <div class="row m-b-20">
              <div class="col-md-12">
                <h3 class="text-center txt-primary">Thank You</h3>
                <p>Your Account has been sucessfully Verified</p>
                <p><a style="color:green" routerLink="['/login']">Click here </a>to Navigate to Login Page</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>