<div class="page-body">
  <div class="row">
    <div class="col-lg-12">
      <div class="m-t-15">
        <!-- info card start -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <!-- Profile Image -->
              <ng-template #contentSkill let-modal let-c="close" let-d="dismiss">
                <div class="modal-header">
                  <h4 class="modal-title">Merge Skills</h4>
                  <button type="button" class="close" aria-label="Close" (click)="c('Save click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <label class="">Merged Skill Name*</label>
                  <input type="text" class="form-control" name="mergeSkillName" [(ngModel)]="domChanges.mergeSkillName"
                    #mergeSkillName="ngModel" placeholder="Merged Skill Name">
                  <p class="text-warning small">This Name will be Updated in all releated Records.</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" (click)="c('closed');mergeSkill()">Merge</button>
                </div>
              </ng-template>
              <!-- Profile Skill Set -->
              <div class="card-header">
                <h4 class="sub-title"> Skill Sets &nbsp;&nbsp; <i class="fa fa-info" placement="top" ngbTooltip="Admin Can Add Skills Sets"></i>
                  <a class="f-right" (click)="domChanges.skillSetEdited=true;adskillFrm.submitted=false;domChanges.skillEdit=false;skillsSetMdl={}">
                    Add
                    Skill</a></h4>
                <form class="md-float-material" (ngSubmit)="adskillFrm.form.valid && addOrUpdateSkill()" #adskillFrm="ngForm"
                  novalidate>
                  <div class="job-details-list" *ngIf="domChanges.skillSetEdited">
                    <div class="input-group col-lg-12">
                      <div class="col-md-2">
                        <label>Skill Name*</label>
                      </div>
                      <div class="col-md-10">
                        <input type="text" class="form-control" name="skillName" placeholder="Skill Name" [(ngModel)]="skillsSetMdl.skillName"
                          #skillName="ngModel" [ngClass]="{ 'is-invalid': adskillFrm.submitted && skillName.invalid }"
                          required>
                        <div *ngIf="adskillFrm.submitted && skillName.invalid" class="invalid-feedback text-left">
                          <div *ngIf="skillName.errors.required">Skill Name Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right" *ngIf="domChanges.skillSetEdited">
                      <br>
                      <a id="edit-cancel" class="btn btn-default waves-effect" (click)="domChanges.skillSetEdited=false;adskillFrm.submitted=false">Cancel</a>&nbsp;&nbsp;
                      <button type="submit" class="btn btn-primary waves-effect waves-light m-r-20">{{domChanges.skillEdit
                        ? 'Update':'Save'}}</button>
                      <hr>
                    </div>
                  </div>
                </form>
                <div class="unique-margin-ul table-responsive">
                  <button *ngIf="domChanges.isUserWantMergeSkills" (click)="openModel(contentSkill)" class="btn btn-primary btn-round">Merge</button>
                  <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                      <tr>
                        <th style="width: 10%">
                          Select
                        </th>
                        <th style="width: 40%">
                          Skill Name
                        </th>
                        <th style="width: 30%">
                          Tag
                        </th>
                        <th style="width: 30%">
                          Opted By Users
                        </th>
                        <th style="width: 20%">
                          Operations
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of skillSetList">
                        <th><input type="checkbox" name="check" [(ngModel)]="domChanges.selectedItem[item.id]" #check="ngModel"
                            (change)="selectedSkill(item)" *ngIf="item?.others"></th>
                        <td>{{item?.skillName}}</td>
                        <td>{{item?.others ? 'Others':'Published'}}</td>
                        <td>{{item?.skillSets ? item.skillSets?.length:'0'}}</td>
                        <td class="text-center">
                          <i class="fa fa-pencil fa-1x text-primary text-center f-18" placement="top" ngbTooltip="Edit Selected Skill"
                            (click)="editSkill(item)"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>