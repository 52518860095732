<div class="page-body">
  <div class="page-header card">

  </div>
  <!--profile cover start-->
  <div class="row">
    <div class="col-lg-12">
      <div class="cover-profile">
        <div class="profile-bg-img">
          <span placement="top"
            ngbTooltip="Click on Image to Upload Background Image We will Recommended to use Dimensions 1660 × 300. Maximum file size 2 MB. Image types include: JPG, JPEG or PNG.">
            <input type="file" (change)="onFileChanged($event,'bgImg');openbg(contentbg)" placeholder="Profile Pic"
              name="file" accept="image/*" #file style="display:none">
            <img *ngIf="profileMdl?.bgImg" class="profile-bg-img img-fluid img-bg-cus"
              [src]="profileMdl.bgImg | safeImgUrl" alt="bg-img" (click)="file.click()">
            <img *ngIf="!profileMdl?.bgImg" class="profile-bg-img img-fluid img-bg-cus" (click)="file.click()"
              src="assets/images/user-profile/bg-img1.jpg" alt="bg-img">
          </span>
          <div class="card-block user-info">
            <div class="col-md-12">
              <div class="media-left">
                <a class="profile-image">
                  <span placement="top"
                    ngbTooltip="Click on Image to Upload Profile Picture and It We will Recommended to use Dimensions 640 × 640. Maximum file size 2 MB. Image types include: JPG, JPEG or PNG.">
                    <input type="file" (change)="onFileChanged($event,'profile');open(content,'profile')"
                      placeholder="Profile Pic" name="file" #file1 style="display:none">
                    <img *ngIf="profileMdl?.profilePic" class="user-img img-radius"
                      [src]="profileMdl.profilePic | safeImgUrl" alt="user-img" (click)="file1.click()">
                    <img *ngIf="!profileMdl?.profilePic" class="user-img img-radius" src="assets/images/noImage.png"
                      alt="user-img" (click)="file1.click()">
                  </span>
                </a>
              </div>
              <div class="media-body row">
                <div class="col-lg-12">
                  <div class="user-title">
                    <h2>{{profileMdl?.firstName ? (profileMdl?.firstName+' '+profileMdl?.lastName):''}}</h2>
                    <span class="text-white">{{profileMdl?.designation ? profileMdl?.designation:''}}</span>
                  </div>
                </div>
                <div>
                  <div class="pull-right cover-btn">
                    <button type="button" class="btn btn-primary m-r-10 m-b-5"><i class="icofont icofont-link"></i>
                      Link Your Company</button>
                    <!-- [routerLink]="['/dashboard/yourCompany']" -->
                    <button type="button" class="btn btn-primary"><i class="icofont icofont-plus"></i>
                      {{profileMdl?.companyId ? 'View Your Company':'Create Your Company'}} </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ///Model Template -->
    <!-- Profile Image -->
    <ng-template #content let-modal let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title">Crop Your Image</h4>
        <button type="button" class="close" aria-label="Close" (click)="c('Save click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" format="png"
         onlyScaleDown="true" [resizeToWidth]="204"
          roundCropper="true" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="c('closed');updateProfile()">Save</button>
      </div>
    </ng-template>

    <!-- /// background Image -->
    <ng-template #contentbg let-modal let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title">Crop Your Image</h4>
        <button type="button" class="close" aria-label="Close" (click)="c('Save click');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEventBg" [maintainAspectRatio]="false" [aspectRatio]="16/9"
          format="png" [resizeToWidth]="1660" cropperMinWidth="1660" (imageCropped)="imageCroppedBg($event)"
          (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="c('closed');updateProfile()">Save</button>
      </div>
    </ng-template>


    <!-- /// End /// -->
  </div>
  <!--profile cover end-->

  <div class="row">
    <div class="col-lg-12">
      <div class="md-tabs md-card-tabs">
        <ngb-tabset>
          <ngb-tab title="My Profile">
            <ng-template ngbTabContent>
              <div class="m-t-15">
                <!-- info card start -->
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-header">
                        <div class="row">
                          <div class="col-md-7">
                            <h5 *ngIf="!domChanges.profileNameEdited" (click)="domChanges.profileNameEdited=true">{{profileMdl?.firstName
                              ? profileMdl?.firstName+'
                              '+profileMdl?.lastName : ''}} &nbsp;&nbsp; <i
                                *ngIf="!domChanges.profileNameEdited && profileMdl?.firstName" class="fa fa-info-circle"
                                placement="top" ngbTooltip="Click on Name or Email to Edit"></i></h5>
                            <span *ngIf="!domChanges.profileNameEdited" class="text-muted f-14 m-b-10"
                              [innerHTML]="profileMdl?.email" (click)="domChanges.profileNameEdited=true"></span>
                            <span *ngIf="!domChanges.profileNameEdited" class="text-muted f-14 m-b-10"
                              [innerHTML]="profileMdl?.designation" (click)="domChanges.profileNameEdited=true"></span>
                          </div>
                          <!-- <div class="col-md-5" *ngIf="profileBuilder">
                            <b> Do You Want to Publish Your Profile:&nbsp;&nbsp;&nbsp;
                              <label>
                                <input type="radio" name="profile12" [value]="true" [(ngModel)]="profileBuilder.isPublic"
                                  (change)="saveorUpdateBuildProfile(profileBuilder)"><i class="helper"></i>
                                &nbsp;Yes
                              </label>&nbsp;&nbsp;&nbsp;
                              <label>
                                <input type="radio" name="profile12" [value]="false" [(ngModel)]="profileBuilder.isPublic"
                                  (change)="saveorUpdateBuildProfile(profileBuilder)"><i class="helper"></i>
                                &nbsp;No
                              </label>
                            </b>
                          </div> -->
                        </div>
                        <div class="input-group col-lg-12" *ngIf="domChanges.profileNameEdited">
                          <div class="col-md-2">
                            <label>First Name*</label>
                          </div>
                          <div class="col-md-10">
                            <input type="text" class="form-control" name="fname" placeholder="First Name"
                              [(ngModel)]="profileMdl.firstName">
                          </div>
                        </div>
                        <div class="input-group col-lg-12" *ngIf="domChanges.profileNameEdited">
                          <div class="col-md-2">
                            <label>Last Name*</label>
                          </div>
                          <div class="col-md-10">
                            <input type="text" class="form-control" name="lname" placeholder="Last Name"
                              [(ngModel)]="profileMdl.lastName">
                          </div>
                        </div>
                        <div class="input-group col-lg-12" *ngIf="domChanges.profileNameEdited">
                          <div class="col-md-2">
                            <label>Email*</label>
                          </div>
                          <div class="col-md-10">
                            <input type="email" class="form-control" name="email" placeholder="Email Id"
                              [(ngModel)]="profileMdl.email">
                          </div>
                        </div>
                        <div class="input-group col-lg-12" *ngIf="domChanges.profileNameEdited">
                          <div class="col-md-2">
                            <label>Designation*</label>
                          </div>
                          <div class="col-md-10">
                            <input type="text" class="form-control" name="designation" placeholder="Designation"
                              [(ngModel)]="profileMdl.designation">
                          </div>
                        </div>
                        <div class="text-right" *ngIf="domChanges.profileNameEdited">
                          <br>
                          <a class="btn btn-primary waves-effect waves-light m-r-20" (click)="updateProfile();">Save</a>
                          <a id="edit-cancel" class="btn btn-default waves-effect"
                            (click)="domChanges.profileNameEdited=false">Cancel</a>
                        </div>
                      </div>
                      <div class="card-header">
                        <!-- //Profile summary -->
                        <h4 class="sub-title" (click)="domChanges.profileSummaryEdited=true">Professional
                          Summary &nbsp;&nbsp; <i class="fa fa-info-circle" placement="top"
                            ngbTooltip="You can Click to  Add or Update Professional Summary"></i>
                        </h4>
                        <div class="unique-margin">
                          <p *ngIf="!domChanges.profileSummaryEdited && profileBuilder?.profileSummary"
                            [innerHTML]="profileBuilder.profileSummary | safeHtml"
                            (click)="domChanges.profileSummaryEdited=true"></p>
                          <p *ngIf="!domChanges.profileSummaryEdited && !profileBuilder?.profileSummary"
                            (click)="domChanges.profileSummaryEdited=true">No
                            Data to
                            Display</p>
                          <angular-editor *ngIf="domChanges.profileSummaryEdited"
                            [(ngModel)]="profileBuilder.profileSummary" [config]="config"></angular-editor>
                          <div class="text-right" *ngIf="domChanges.profileSummaryEdited">
                            <br>
                            <a class="btn btn-primary waves-effect waves-light m-r-20"
                              (click)="saveorUpdateBuildProfile(profileBuilder)">Save</a>
                            <a id="edit-cancel" class="btn btn-default waves-effect"
                              (click)="domChanges.profileSummaryEdited=false">Cancel</a>
                          </div>
                        </div>
                      </div>

                      <!-- Profile Skill Set -->
                      <div class="card-header">
                        <h4 class="sub-title"> Skill Set &nbsp;&nbsp; <i class="fa fa-info-circle" placement="top"
                            ngbTooltip="You can Add New Skill to Click Add Skill Button and Click on a particular Skill to Update"></i>
                          <a class="f-right" (click)="domChanges.skillSetEdited=true;skillFrm.submitted=false"> Add
                            New</a></h4>
                        <form class="md-float-material" (ngSubmit)="skillFrm.form.valid && addorUpdateSkillSet()"
                          #skillFrm="ngForm" novalidate>
                          <div class="job-details-list" *ngIf="domChanges.skillSetEdited">
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Skill Name*</label>
                              </div>
                              <div class="col-md-10">
                                <ng-select name="skillName" [items]="definedSkillsList" placeholder="Select Or Search"
                                  autofocus bindLabel="skillName" bindValue="id" [addTag]="true" addTagText="Others"
                                  (change)="onAddSkillToList($event)" [typeahead]="people3input$"
                                  [(ngModel)]="domChanges.skillId">
                                </ng-select>
                                <!-- <div *ngIf="skillFrm.submitted && skillName.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="skillName.errors.required">Skill Name Name is required</div>
                                </div> -->
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Skill Description*</label>
                              </div>
                              <div class="col-md-10">
                                <angular-editor class="form-control" [config]="config" name="desc"
                                  placeholder="Skills Description" [(ngModel)]="skillsSetMdl.skillDesc" #desc="ngModel"
                                  [ngClass]="{ 'is-invalid': skillFrm.submitted && desc.invalid }" required>
                                </angular-editor>
                                <div *ngIf="skillFrm.submitted && desc.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="desc.errors.required">Skill Description Required</div>
                                </div>
                              </div>
                            </div>
                            <div class="text-right" *ngIf="domChanges.skillSetEdited">
                              <br>
                              <button type="submit" class="btn btn-primary waves-effect waves-light m-r-20">{{domChanges.skillEdit
                                ? 'Update':'Save'}}</button>
                              <a id="edit-cancel" class="btn btn-default waves-effect"
                                (click)="domChanges.skillSetEdited=false;skillFrm.submitted=false;skillsSetMdl={}">Cancel</a>
                            </div>
                          </div>
                        </form>
                        <div class="unique-margin-ul" *ngIf="!domChanges.skillSetEdited">
                          <ul *ngFor="let item of skillSetList; let i = index;">
                            <i class="fa fa-trash-o f-right" style="color:rgb(235, 66, 0)" (click)="deleteSkill(item,i)"
                              placement="top" ngbTooltip="Delete Skill"></i>
                            <span (click)="editSkill(item,i)">
                              <h6><u>{{item.definedSkillSets ? item.definedSkillSets.skillName:'--'}}</u></h6>
                              <li style="margin-left:10px" [innerHTML]="item.skillDesc | safeHtml"></li>
                            </span>
                            <br>
                          </ul>
                          <p style="margin-left:1% !important" *ngIf="skillSetList?.length<1">No Data to Display</p>
                        </div>
                      </div>

                      <!-- Skills End -->

                      <!-- Qualifications Start -->

                      <div class="card-header">
                        <h4 class="sub-title"> Qualifications &nbsp;&nbsp; <i class="fa fa-info-circle" placement="top"
                            ngbTooltip="You can Add or Edit Qualifications"></i>
                          <a class="f-right"
                            (click)="domChanges.profileQualificationEdited=true;qualFrm.submitted=false">
                            Add
                            New</a></h4>
                        <form class="md-float-material" (ngSubmit)="qualFrm.form.valid && addorUpdateQualification()"
                          #qualFrm="ngForm" novalidate>
                          <div class="job-details-list" *ngIf="domChanges.profileQualificationEdited">
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Qualification*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="qual"
                                  placeholder="Qualification ( Ex: M - Tech)"
                                  [(ngModel)]="qualificationMdl.qualification" #qual="ngModel"
                                  [ngClass]="{ 'is-invalid': qualFrm.submitted && qual.invalid }" required>
                                <div *ngIf="qualFrm.submitted && qual.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="qual.errors.required">Qualification is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>University / Board*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="board" placeholder="University / Board"
                                  [(ngModel)]="qualificationMdl.board" #board="ngModel"
                                  [ngClass]="{ 'is-invalid': qualFrm.submitted && board.invalid }" required>
                                <div *ngIf="qualFrm.submitted && board.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="board.errors.required">University or Board is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Pass Out Year*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="passout" placeholder="Pass Out Year"
                                  [(ngModel)]="qualificationMdl.passout" #passout="ngModel"
                                  [ngClass]="{ 'is-invalid': qualFrm.submitted && passout.invalid }" required>
                                <div *ngIf="qualFrm.submitted && passout.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="passout.errors.required">Passout Year is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Marks / Percentage</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="marks" placeholder="Marks / Percentage"
                                  [(ngModel)]="qualificationMdl.marks" #marks="ngModel"
                                  [ngClass]="{ 'is-invalid': qualFrm.submitted && marks.invalid }">
                                <div *ngIf="qualFrm.submitted && marks.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="marks.errors.required">Marks or Percentage is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="text-right">
                              <br>
                              <button type="submit" class="btn btn-primary waves-effect waves-light m-r-20">{{domChanges.qualificationEdit
                                ? 'Update':'Save'}}
                              </button>
                              <a id="edit-cancel" class="btn btn-default waves-effect"
                                (click)="domChanges.profileQualificationEdited=false;qualFrm.submitted=false">Cancel</a>
                            </div>
                          </div>
                        </form>
                        <div class="unique-margin-ul" *ngIf="!domChanges.profileQualificationEdited">
                          <ul *ngFor="let item of qualificationList; let i = index;">
                            <i class="fa fa-trash-o f-right" style="color:rgb(235, 66, 0)"
                              (click)="deleteQualification(item,i)" placement="top"
                              ngbTooltip="Delete Qualification"></i>
                            <span (click)="editQualification(item)">
                              <label><b>Qualification</b> : {{item.qualification}}</label><br>
                              <label><b>University / Board</b> : {{item.board}}</label><br>
                              <label><b>Pass Out Year</b> : {{item.passout}}</label><br>
                              <label><b>Marks / Percentage</b> : {{item.marks}}</label><br>
                            </span>
                            <br>
                          </ul>
                          <p style="margin-left:1% !important" *ngIf="qualificationList?.length<1">No Data to Display
                          </p>
                        </div>
                      </div>

                      <!-- End -->

                      <!-- Projects -->
                      <!-- Client Logo -->
                      <ng-template #contentClient let-modal let-c="close" let-d="dismiss">
                        <div class="modal-header">
                          <h4 class="modal-title">Crop Your Image</h4>
                          <button type="button" class="close" aria-label="Close" (click)="c('Save click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <image-cropper [imageChangedEvent]="imageChangedEventClient" [maintainAspectRatio]="false"
                            [aspectRatio]="1/1" [resizeToWidth]="260" format="png"
                            (imageCropped)="imageCroppedClient($event)" (imageLoaded)="imageLoaded()"
                            (loadImageFailed)="loadImageFailed()"></image-cropper>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-light" (click)="c('closed')">Save</button>
                        </div>
                      </ng-template>
                      <div class="card-header">
                        <h4 class="sub-title"> Work Experience &nbsp;&nbsp; <i class="fa fa-info-circle" placement="top"
                            ngbTooltip="You Can Add or edit Your Work Experience"></i>
                          <a class="f-right" (click)="domChanges.projectEdited=true;projectFrm.submitted=false"> Add
                            New</a></h4>
                        <form class="md-float-material" (ngSubmit)="projectFrm.form.valid && addorUpdateProject()"
                          #projectFrm="ngForm" novalidate>
                          <div class="job-details-list" *ngIf="domChanges.projectEdited">
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Client Name*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="client" placeholder="Client Name"
                                  [(ngModel)]="projectsMdl.client" #client="ngModel"
                                  [ngClass]="{ 'is-invalid': projectFrm.submitted && client.invalid }" required>
                                <div *ngIf="projectFrm.submitted && client.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="client.errors.required">Client Name is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Project / Assignment Name*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="projectName" placeholder="Project Name"
                                  [(ngModel)]="projectsMdl.projectName" #projectName="ngModel"
                                  [ngClass]="{ 'is-invalid': projectFrm.submitted && projectName.invalid }" required>
                                <div *ngIf="projectFrm.submitted && projectName.invalid"
                                  class="invalid-feedback text-left">
                                  <div *ngIf="projectName.errors.required">Project / Assignment name is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Your Role*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="role" placeholder="Your Role"
                                  [(ngModel)]="projectsMdl.role" #role="ngModel"
                                  [ngClass]="{ 'is-invalid': projectFrm.submitted && role.invalid }" required>
                                <div *ngIf="projectFrm.submitted && role.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="role.errors.required">Your Role is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Duration*</label>
                              </div>
                              <div class="col-md-5">
                                <!-- <input type="date" class="form-control" placeholder="From Date (YYYY-MM-DD)" name="from"
                                  [(ngModel)]="projectsMdl.from" dateFormat="dd-MMM-yyyy" #from="ngModel"
                                  (ngModelChange)="projectsMdl.from  = $event">
                                &nbsp;&nbsp; -->
                                <!-- <span class="input-group-addon" (click)="from.toggle()"><i class="icofont icofont-calendar"></i></span> -->
                                <input class="form-control" placeholder="From Date (YYYY-MM-DD)" name="from"
                                  [(ngModel)]="projectsMdl.from" #from="ngModel" ngbDatepicker dateFormat="dd-MMM-yyyy"
                                  #frm="ngbDatepicker" (click)="frm.toggle()"
                                  [ngClass]="{ 'is-invalid': projectFrm.submitted && from.invalid }"
                                  [maxDate]="maxDate">
                                <div *ngIf="projectFrm.submitted && from.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="from.errors.required">From Date is required</div>

                                </div>
                              </div>
                              <div class="col-md-5">
                                <!-- <input type="date" class="form-control" placeholder="To Date (YYYY-MM-DD)" name="to"
                                  [(ngModel)]="projectsMdl.to" #to="ngModel"> -->
                                <!-- <span class="input-group-addon" (click)="to.toggle()"><i class="icofont icofont-calendar"></i></span> -->
                                <input class="form-control" placeholder=" To Date (YYYY-MM-DD)" name="to"
                                  [(ngModel)]="projectsMdl.to" ngbDatepicker dateFormat="dd-MMM-yyyy"
                                  #to="ngbDatepicker" (click)="to.toggle()" [minDate]="projectsMdl?.from"
                                  [maxDate]="maxDate">
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Project / Assignment Overview*</label>
                              </div>
                              <div class="col-md-10">
                                <textarea class="form-control" name="projectOverview" placeholder="Project Overview"
                                  [(ngModel)]="projectsMdl.projectOverview" #projectOverview="ngModel"
                                  [ngClass]="{ 'is-invalid': projectFrm.submitted && projectOverview.invalid }"
                                  required></textarea>
                                <div *ngIf="projectFrm.submitted && projectOverview.invalid"
                                  class="invalid-feedback text-left">
                                  <div *ngIf="projectOverview.errors.required">Project / Assignment Overview is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Scope of work and accountabilities*</label>
                              </div>
                              <div class="col-md-10">
                                <angular-editor class="form-control" [config]="config" name="scopeAndAcc"
                                  placeholder="Scope of work and accountabilities" [(ngModel)]="projectsMdl.scopeAndAcc"
                                  #scopeAndAcc="ngModel"
                                  [ngClass]="{ 'is-invalid': projectFrm.submitted && scopeAndAcc.invalid }" required>
                                </angular-editor>
                                <div *ngIf="projectFrm.submitted && scopeAndAcc.invalid"
                                  class="invalid-feedback text-left">
                                  <div *ngIf="scopeAndAcc.errors.required">Scope and Accountabilities is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Key Achievements*</label>
                              </div>
                              <div class="col-md-10">
                                <angular-editor class="form-control" [config]="config" name="keyAchivements"
                                  placeholder="Key Achievements" [(ngModel)]="projectsMdl.keyAchievements"
                                  #keyAchivements="ngModel"
                                  [ngClass]="{ 'is-invalid': projectFrm.submitted && keyAchivements.invalid }" required>
                                </angular-editor>
                                <div *ngIf="projectFrm.submitted && keyAchivements.invalid"
                                  class="invalid-feedback text-left">
                                  <div *ngIf="keyAchivements.errors.required">Key Achievements is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12" placement="top"
                              ngbTooltip="Client Logo We will Recommended to use Dimensions 640 × 640. Maximum file size 2 MB. Image types include: JPG, JPEG or PNG.">
                              <label class="col-sm-2 col-form-label">Client Logo<br> (If Any)</label>
                              <div class="col-sm-10">
                                <input type="file" name="image" class="form-control"
                                  (change)="onFileChanged($event,'client');open(contentClient,'client')">
                                <br><span style="color:red" *ngIf="domChanges.errorClientLogoFormat">Invalid File
                                  Format, It
                                  will not be saved.</span>
                              </div>
                            </div>
                            <div class="input-group col-lg-12" *ngIf="projectsMdl.logo">
                              <label class="col-sm-2 col-form-label">Image Preview</label>
                              <div class="col-sm-10">
                                <img style="width:260px;height:100px;" [src]="projectsMdl.logo | safeImgUrl"
                                  (click)="open(contentClient,'client')" />
                              </div>
                            </div>
                            <div class="text-right" *ngIf="domChanges.projectEdited">
                              <br>
                              <button type="submit" class="btn btn-primary waves-effect waves-light m-r-20">{{domChanges.projectEdit
                                ? 'Update':'Save'}}
                              </button>
                              <a id="edit-cancel" class="btn btn-default waves-effect"
                                (click)="domChanges.projectEdited=false;projectFrm.submitted=false">Cancel</a>
                            </div>
                          </div>
                        </form>
                        <div class="unique-margin-ul" *ngIf="!domChanges.projectEdited">
                          <ul *ngFor="let item of projectList; let i = index;">
                            <i class="fa fa-trash-o f-right" style="color:rgb(235, 66, 0)"
                              (click)="deleteProject(item,i)" placement="top" ngbTooltip="Delete Project"></i>
                            <span (click)="editProject(item,i)">
                              <h6 class="project-label"><u>Project / Assignment : {{i+1}}</u></h6>
                              <div class="row">
                                <div class="col-md-9">
                                  <label><b>Client</b>: {{item.client}}</label><br>
                                  <label><b>Duration</b>: {{(item.from | date:'dd-MMM-yyyy')+' To '+ (item.to ?
                                    (item.to |
                                    date:'dd-MMM-yyyy'):'till Date')}}</label><br>
                                  <label><b>Project / Assignment Name</b>: {{item.projectName}}</label><br>
                                  <label><b>Role</b>: {{item.role}}</label><br>
                                </div>
                                <div class="col-md-3" *ngIf="item.logo">
                                  <img *ngIf="item.logo" style="width:260px;height:100px;" [src]="item.logo | safeImgUrl"
                                    alt="bg-img">
                                </div>

                              </div>

                              <label class="project-label"><b>Project / Assignment Overview</b>:</label><br>
                              <label class="project-label-content"
                                [innerHTML]="item.projectOverview | safeHtml"></label><br>
                              <label class="project-label"><b>Scope of work and Accountabilities</b>:</label><br>
                              <label class="project-label-content"
                                [innerHTML]="item.scopeAndAcc | safeHtml"></label><br>
                              <label class="project-label"><b>Key Achievements</b>:</label><br>
                              <label class="project-label-content"
                                [innerHTML]="item.keyAchievements | safeHtml"></label><br>
                            </span>
                            <br>
                          </ul>
                          <p style="margin-left:1% !important" *ngIf="projectList?.length<1">No Data to Display</p>
                        </div>
                      </div>

                      <!-- End -->

                      <!-- /// Employment History Start -->
                      <div class="card-header">
                        <h4 class="sub-title"> Employment History &nbsp;&nbsp; <i class="fa fa-info-circle"
                            placement="top" ngbTooltip="You can Add or Edit Employment History"></i>
                          <a class="f-right" (click)="domChanges.employementHistoryEdited=true;empFrm.submitted=false">
                            Add
                            New</a></h4>
                        <form class="md-float-material" (ngSubmit)="empFrm.form.valid && addorUpdateEmpHistory()"
                          #empFrm="ngForm" novalidate>
                          <div class="job-details-list" *ngIf="domChanges.employementHistoryEdited">
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Employer*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="employer" placeholder="Employer Name"
                                  [(ngModel)]="empMdl.employer" #employer="ngModel"
                                  [ngClass]="{ 'is-invalid': empFrm.submitted && employer.invalid }" required>
                                <div *ngIf="empFrm.submitted && employer.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="employer.errors.required">Employer Name is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Designation*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="desig" placeholder="Description"
                                  [(ngModel)]="empMdl.designation" #desig="ngModel"
                                  [ngClass]="{ 'is-invalid': empFrm.submitted && desig.invalid }" required>
                                <div *ngIf="empFrm.submitted && desig.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="desig.errors.required">Designation is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Duration*</label>
                              </div>
                              <div class="col-md-5">
                                <input class="form-control" placeholder="From Date (YYYY-MM-DD)" name="empfrom"
                                  [(ngModel)]="empMdl.from" ngbDatepicker dateFormat="dd-MMM-yyyy"
                                  #empfrom="ngbDatepicker" (click)="empfrom.toggle()"
                                  [ngClass]="{ 'is-invalid': projectFrm.submitted && Efrom.invalid } "
                                  [maxDate]="maxDate">

                              </div>
                              <div class="col-md-5">
                                <input class="form-control" placeholder="To Date( YYYY-MM-DD )" name="empto"
                                  [(ngModel)]="empMdl.to" ngbDatepicker dateFormat="dd-MMM-yyyy" #empto="ngbDatepicker"
                                  (click)="empto.toggle()" [minDate]="empMdl?.from" [maxDate]="maxDate">
                              </div>
                            </div>

                            <div class="text-right">
                              <br>
                              <button type="submit" class="btn btn-primary waves-effect waves-light m-r-20">{{domChanges.empHistoryEdit
                                ? 'Update':'Save'}}
                              </button>
                              <a id="edit-cancel" class="btn btn-default waves-effect"
                                (click)="domChanges.employementHistoryEdited=false;empFrm.submitted=false">Cancel</a>
                            </div>
                          </div>
                        </form>
                        <div class="unique-margin-ul" *ngIf="!domChanges.employementHistoryEdited">
                          <ul *ngFor="let item of empHistoryList; let i = index;">
                            <i class="fa fa-trash-o f-right" style="color:rgb(235, 66, 0)"
                              (click)="deleteEmpHistory(item,i)" placement="top" ngbTooltip="Delete Employeement"></i>
                            <span (click)="editEmpHistory(item)">
                              <!-- <label><b>Employer</b>: {{item.employer}}</label><br>
                              <label><b>Designation</b>: {{item.designation}}</label><br>
                              <label><b>Duration</b>: {{(item.from | date:'dd-MMM-yyyy')+' To '+ (item.to ? (item.to |
                                date:'dd-MMM-yyyy'):'till Date')}}</label><br> -->
                              <label *ngIf="!item.to">Working as <b>{{item.designation}}</b> at <b>{{item.employer}}</b>  from
                                {{(item.from | date:'dd-MMM-yyyy')}} to till date</label>
                              <label *ngIf="item.to">Worked as <b>{{item.designation}} </b> at <b>{{item.employer}}</b>  from
                                {{(item.from | date:'dd-MMM-yyyy')}} to {{(item.to | date:'dd-MMM-yyyy')}}</label>
                            </span>
                            <br>
                          </ul>
                          <p style="margin-left:1% !important" *ngIf="empHistoryList?.length<1">No Data to Display</p>
                        </div>
                      </div>
                      <!-- /// End/// -->

                      <!-- /// Certifications Start -->
                      <div class="card-header">
                        <h4 class="sub-title"> Certifications &nbsp;&nbsp; <i class="fa fa-info-circle" placement="top"
                            ngbTooltip="You can Add or Edit Certifications"></i>
                          <a class="f-right" (click)="domChanges.certificationsEdited=true;certFrm.submitted=false">
                            Add
                            New</a></h4>
                        <form class="md-float-material" (ngSubmit)="certFrm.form.valid && addorUpdateCertification()"
                          #certFrm="ngForm" novalidate>
                          <div class="job-details-list" *ngIf="domChanges.certificationsEdited">
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Certification Name*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="cname" placeholder="Certification Name"
                                  [(ngModel)]="certificationMdl.name" #cname="ngModel"
                                  [ngClass]="{ 'is-invalid': certFrm.submitted && cname.invalid }" required>
                                <div *ngIf="certFrm.submitted && cname.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="cname.errors.required">Certification Name is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Certification Year*</label>
                              </div>
                              <div class="col-md-10">
                                <input type="text" class="form-control" name="year" placeholder="Certification Year"
                                  [(ngModel)]="certificationMdl.year" #year="ngModel"
                                  [ngClass]="{ 'is-invalid': certFrm.submitted && year.invalid }" required>
                                <div *ngIf="certFrm.submitted && year.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="year.errors.required">Certification Year is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="input-group col-lg-12">
                              <div class="col-md-2">
                                <label>Certification Body*</label>
                              </div>
                              <div class="col-md-10">
                                <textarea class="form-control" name="cbody" placeholder="Certification Body"
                                  [(ngModel)]="certificationMdl.body" #cbody="ngModel"
                                  [ngClass]="{ 'is-invalid': certFrm.submitted && cbody.invalid }" required></textarea>
                                <div *ngIf="certFrm.submitted && cbody.invalid" class="invalid-feedback text-left">
                                  <div *ngIf="cbody.errors.required">Certification Body is required</div>
                                </div>
                              </div>
                            </div>


                            <div class="text-right">
                              <br>
                              <button type="submit" class="btn btn-primary waves-effect waves-light m-r-20">{{domChanges.certificationsEdit
                                ? 'Update':'Save'}}
                              </button>
                              <a id="edit-cancel" class="btn btn-default waves-effect"
                                (click)="domChanges.certificationsEdited=false;certFrm.submitted=false">Cancel</a>
                            </div>
                          </div>
                        </form>
                        <div class="unique-margin-ul" *ngIf="!domChanges.certificationsEdited">
                          <ul *ngFor="let item of certificationList; let i = index;">
                            <i class="fa fa-trash-o f-right" style="color:rgb(235, 66, 0)"
                              (click)="deleteCertification(item,i)" placement="top"
                              ngbTooltip="Delete Certification"></i>
                            <span (click)="editCertification(item)">
                              <!-- <label><b>Ceritifcation Name</b>: {{item.name ? item.name:''}}</label><br>
                              <label><b>Ceritifcation Year</b>: {{item.year ? item.year:''}}</label><br>
                              <label><b>Ceritifcation Body</b>: {{item.body ? item.body:''}}</label><br> -->
                              <label>Certified as <b>{{item.name ? item.name:''}}</b> by {{item.body ? item.body:'--'}} in {{item.year ? item.year:''}}</label><br>
                            </span>
                            <br>
                          </ul>
                          <p style="margin-left:1% !important" *ngIf="certificationList?.length<1">No Data to Display
                          </p>
                        </div>
                      </div>
                      <!-- /// End/// -->
                      <!-- /// Certifications Start -->
                      <div class="card-header">
                        <h4 class="sub-title" (click)="deleteAccount()"> Delete My Account &nbsp;&nbsp; <i
                            class="fa fa-info-circle" placement="top" ngbTooltip="You Can Delete Your Account."></i>
                        </h4>
                      </div>
                      <!-- /// End/// -->

                    </div>
                  </div>
                </div>
              </div>
              <!-- info card end -->
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Personal Info">
            <ng-template ngbTabContent>
              <div class="m-t-15">
                <div class="card">
                  <div class="card-header">
                    <h4 class="sub-title">About Me
                      <button class="btn-edit-profile f-right" *ngIf="!domChanges.editUser"
                        (click)="domChanges.editUser=true" placement="top" ngbTooltip="Edit Profile">
                        <i class="fa fa-pencil"></i></button>
                    </h4>
                  </div>
                  <div class="card-block">
                    <div class="view-info" *ngIf="!domChanges.editUser">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="general-info">
                            <div class="row">
                              <div class="col-lg-12 col-xl-6">
                                <div class="table-responsive">
                                  <table class="table m-0">
                                    <tbody>
                                      <tr>
                                        <th scope="row">User ID</th>
                                        <td>{{profileMdl?.username ? profileMdl?.username:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Full Name: </th>
                                        <td>{{profileMdl?.firstName ? (profileMdl?.firstName+'
                                          '+profileMdl?.lastName):'--'}}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Date Of Birth: </th>
                                        <td>{{profileMdl?.dob ? (profileMdl?.dob | date:'dd-MMM-yyyy'):'--'}}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Website: </th>
                                        <td><a>{{profileMdl?.website ? profileMdl?.website:'--'}}</a></td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Location: </th>
                                        <td>{{profileMdl?.location ? profileMdl?.location:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Secondary Email: </th>
                                        <td>{{secMailsList[0] ? secMailsList[0].email:'--'}}&nbsp;&nbsp;
                                          <a *ngIf="secMailsList[0] && !secMailsList[0].isVerified" class="text-danger"
                                            (click)="verifySecMail(secMailsList[0])">Verify ? &nbsp;&nbsp;</a>
                                          <i *ngIf="secMailsList[0] && secMailsList[0].isVerified"
                                            style="color:green;font-size: 20px" class="fa fa-check-circle "></i>
                                          &nbsp;&nbsp;
                                          <i style="color:blue;font-size: 20px" class="fa fa-caret-down" placement="top"
                                            ngbTooltip="Verify More Emails"
                                            (click)="openSecEmailPopUp(contentSecEmail)"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                                          <i style="color:orange;font-size: 25px" class="fa fa-plus-circle"
                                            placement="top" ngbTooltip="Add More Emails"
                                            (click)="openAddSecEmailPopUp(contentAddSecEmail)"></i>
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <ng-template #contentSecEmail let-modal let-c="close" let-d="dismiss">
                                <div class="modal-header">
                                  <h4 class="modal-title">Verify your Emails</h4>
                                  <button type="button" class="close" aria-label="Close" (click)="c('Save click')">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div class="table-responsive">
                                    <table class="table">
                                      <tbody>
                                        <tr>
                                          <th scope="row">Email: </th>
                                          <th scope="row"> </th>
                                        </tr>
                                        <tr *ngFor="let item of secMailsList">
                                          <td><a>{{item.email ? item.email:'--'}}</a></td>
                                          <td>
                                            <a *ngIf="item && !item.isVerified" class="text-danger"
                                              (click)="verifySecMail(item)">Verify ? &nbsp;&nbsp;</a>
                                            <i *ngIf="item && item.isVerified" style="color:green;font-size: 20px"
                                              class="fa fa-check-circle "></i>
                                          </td>
                                        </tr>

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-light"
                                    (click)="c('closed');getSecMails();">Done</button>
                                </div>
                              </ng-template>
                              <ng-template #contentAddSecEmail let-modal let-c="close" let-d="dismiss">
                                <div class="modal-header">
                                  <h4 class="modal-title">Add More Emails</h4>
                                  <button type="button" class="close" aria-label="Close" (click)="c('Save click')">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div class="row">
                                    <!-- <input type="email" name="ssemail" class="form-control m-15 p-10" [(ngModel)]="domChanges.moreEmails" placeholder="Enter E-Mail ID"> -->
                                    <div class="input-group">
                                      <input type="text" class="form-control m-15 p-10" placeholder="Enter E-Mail ID"
                                        name="ssemail" [(ngModel)]="domChanges.moreEmails" #ssemail="ngModel"
                                        [pattern]="emailPattern"
                                        [ngClass]="{ 'is-invalid': ssemail.invalid && (ssemail.dirty || ssemail.touched)}"
                                        required>
                                      <div
                                        *ngIf="(ssemail.invalid) || (ssemail.invalid && (ssemail.dirty || ssemail.touched))"
                                        class="invalid-feedback text-left m-15">
                                        <div *ngIf="ssemail?.errors?.required">Email is required</div>
                                        <div *ngIf="ssemail.errors.pattern">
                                          Email must be a valid Email address.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button type="button"
                                    [disabled]="ssemail?.errors?.required || ssemail?.errors?.pattern"
                                    class="btn btn-primary" (click)="c('closed');saveMoreEmails()">Save</button>
                                </div>
                              </ng-template>
                              <div class="col-lg-12 col-xl-6">
                                <div class="table-responsive">
                                  <table class="table">
                                    <tbody>
                                      <tr>
                                        <th scope="row">Email: </th>
                                        <td><a>{{profileMdl?.email ? profileMdl?.email:'--'}}</a></td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Mobile Number: </th>
                                        <td>{{profileMdl?.mobile ? profileMdl?.mobile:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Twitter: </th>
                                        <td>{{profileMdl?.twitter ? profileMdl?.twitter:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Skype: </th>
                                        <td>{{profileMdl?.skype ? profileMdl?.skype:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Gender: </th>
                                        <td>{{profileMdl?.gender ? (profileMdl?.gender==1 ?
                                          'Male':(profileMdl?.gender==2 ? 'Female':'Others')):'--'}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="edit-info" *ngIf="domChanges.editUser">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="general-info">
                            <!-- <div class="form-group row">
                              <div class="col-sm-1"></div>
                              <div class="col-sm-5">
                                <p class="text-success">Profile Picture</p>
                                <span placement="top" ngbTooltip="Profile Picture We will Recommended to use Dimensions 640 × 640. Maximum file size 2 MB. Image types include: JPG, JPEG or PNG.">
                                  <img *ngIf="profileMdl?.profilePic" class="img-radius text-center" [src]="profileMdl?.profilePic"
                                    (click)="open(content,'profile')" />
                                  <img *ngIf="!profileMdl?.profilePic" class="img-radius text-center" src="assets/images/noImage.png" />
                                </span>
                                <br><br><a><input type="file" (change)="onFileChanged($event,'profile');open(content,'profile')"
                                    placeholder="Profile Pic" name="file"></a>
                                <br><span style="color:red" *ngIf="this.domChanges.errorFileFormat">Invalid File
                                  Format, It
                                  will not be saved
                                </span>
                              </div>
                              <div class="col-sm-6" placement="top" ngbTooltip="Background Image We will Recommended to use Dimensions 1660 × 300. Maximum file size 2 MB. Image types include: JPG, JPEG or PNG.">
                                <p class="text-success">Background Image</p>
                                <img *ngIf="profileMdl?.bgImg" class="img-bg-up text-center" [src]="profileMdl?.bgImg"
                                  (click)="openbg(contentbg)" />
                                <img *ngIf="!profileMdl?.bgImg" class="img-bg-up" src="assets/images/user-profile/bg-img1.jpg" />
                                <br><br><a><input type="file" (change)="onFileChanged($event,'bgImg');openbg(contentbg)"
                                    placeholder="Profile Pic" name="file"></a>
                                <br><span style="color:red" *ngIf="this.domChanges.errorbgImgFileFormat">Invalid File
                                  Format, It
                                  will not be saved
                                </span>
                              </div>
                            </div> -->
                            <div class="row">
                              <div class="col-lg-6">
                                <table class="table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="First Name">
                                          <span class="input-group-addon"><i class="icofont icofont-user"></i></span>
                                          <input type="text" class="form-control" placeholder="First Name" name="fname"
                                            [(ngModel)]="profileMdl.firstName" #fname="ngModel">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="User Name">
                                          <span class="input-group-addon"><i class="icofont icofont-user"></i></span>
                                          <input type="text" class="form-control" placeholder="User Name" name="uname"
                                            [(ngModel)]="profileMdl.username" [disabled]="true" #uname="ngModel">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="Primary Email">
                                          <span class="input-group-addon"><i class="icofont icofont-email"></i></span>
                                          <input type="text" class="form-control" placeholder="Primary Email"
                                            name="email" [(ngModel)]="profileMdl.email">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="Date Of Birth">
                                          <span class="input-group-addon" (click)="d.toggle()"><i
                                              class="icofont icofont-calendar"></i></span>
                                          <input class="form-control" placeholder="YYYY-MM-DD" name="dp"
                                            [(ngModel)]="dateObj.dob" ngbDatepicker dateFormat="dd-MMM-yyyy"
                                            #d="ngbDatepicker" (click)="d.toggle()">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="Website">
                                          <span class="input-group-addon"><i class="icofont icofont-web"></i></span>
                                          <input type="text" class="form-control" placeholder="website" name="website"
                                            [(ngModel)]="profileMdl.website">
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="Address">
                                          <span class="input-group-addon"><i
                                              class="icofont icofont-location-pin"></i></span>
                                          <input type="text" class="form-control" placeholder="Address" name="location"
                                            [(ngModel)]="profileMdl.location">
                                        </div>
                                      </td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                              <div class="col-lg-6">
                                <table class="table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="Last Name">
                                          <span class="input-group-addon"><i class="icofont icofont-user"></i></span>
                                          <input type="text" class="form-control" placeholder="Last Name" name="lname"
                                            [(ngModel)]="profileMdl.lastName" #lname="ngModel">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="input-group" style="margin-bottom: -13px;margin-left: -15px"
                                          placement="left" ngbTooltip="Phone Number With Country Code">
                                          <int-phone-prefix class="form-control" style="border:none;"
                                            [(ngModel)]="profileMdl.mobile"></int-phone-prefix>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="Secondary Email">
                                          <span class="input-group-addon"><i class="icofont icofont-email"></i></span>
                                          <input type="text" class="form-control" placeholder="Secondary Email"
                                            name="secEmail" [(ngModel)]="profileMdl.secEmail">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="Twitter">
                                          <span class="input-group-addon"><i
                                              class="icofont icofont-social-twitter"></i></span>
                                          <input type="text" class="form-control" placeholder="Twitter Id"
                                            name="twitter" [(ngModel)]="profileMdl.twitter">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="input-group" placement="left" ngbTooltip="Skype">
                                          <span class="input-group-addon"><i
                                              class="icofont icofont-social-skype"></i></span>
                                          <input type="email" class="form-control" placeholder="Skype Id" name="skype"
                                            [(ngModel)]="profileMdl.skype">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-radio" placement="left" ngbTooltip="Gender">
                                          <div class="group-add-on">

                                            <div class="radio radiofill radio-inline">
                                              <b> Gender:
                                              </b>
                                            </div>

                                            <div class="radio radiofill radio-inline">
                                              <label>
                                                <input type="radio" name="gender" [value]="1"
                                                  [(ngModel)]="profileMdl.gender"><i class="helper"></i> Male
                                              </label>
                                            </div>
                                            <div class="radio radiofill radio-inline">
                                              <label>
                                                <input type="radio" name="gender" [value]="2"
                                                  [(ngModel)]="profileMdl.gender"><i class="helper"></i> Female
                                              </label>
                                            </div>
                                            <div class="radio radiofill radio-inline">
                                              <label>
                                                <input type="radio" name="gender" [value]="3"
                                                  [(ngModel)]="profileMdl.gender"><i class="helper"></i> Others
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="text-right">
                              <a class="btn btn-primary waves-effect waves-light m-r-20"
                                (click)="updateProfile()">Save</a>
                              <a id="edit-cancel" class="btn btn-default waves-effect"
                                (click)="domChanges.editUser=false">Cancel</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</div>