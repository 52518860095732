import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpIntercepterService } from 'src/app/shared/services/http-intercepter.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private route: Router, private http: HttpIntercepterService, public toastr: ToastrManager, private activatedRoute: ActivatedRoute) { }
  model: any = {}
  domChanges: any = {
    paramsObj: {}
  };
  registationCompleted: any = false;
  // emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$";
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordPattren = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&_#]){8,}");
  pwdpattern = true;
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.domChanges.params = params.ref;
      if (this.domChanges.params != "direct") {
        let paramsArr = this.domChanges.params.split(',');
        this.domChanges.paramsObj.id = paramsArr[0];
        this.domChanges.paramsObj.email = paramsArr[1];
        this.domChanges.paramsObj.name = paramsArr[2];
        this.model.email = this.domChanges.paramsObj.email;
      }
    });
    this.domChanges.passwordType = 'password';
    this.domChanges.passwordIcon = 'icofont icofont-eye-blocked';
    this.domChanges.cnfpasswordType = 'password';
    this.domChanges.cnfpasswordIcon = 'icofont icofont-eye-blocked';
  }
  register() {
    if (this.model.password != this.domChanges.cnfpass) {
      this.toastr.errorToastr("Passwords Does not Match", 'Oops!');
      return;
    }
    this.http.register('Accounts', this.model).subscribe(res => {
      localStorage.clear();
      this.registationCompleted = true;
    },
      error => {
        if (error._body) {
          let err = JSON.parse(error._body);
          this.toastr.errorToastr(err.error.message, 'Oops!');
        } else {
          this.toastr.errorToastr("Some thing went Wrong with Server, Please Try agin", 'Oops!');
        }
      }
    )
  }
  checkValidation(ev) {
    if (this.passwordPattren.test(ev) == true) {
      this.pwdpattern = true;
    } else {
      this.pwdpattern = false;
    }
  }
  showHidePassword(type: any) {
    if (type == 'cnf') {
      if (this.domChanges.cnfpasswordType == 'password') {
        this.domChanges.cnfpasswordType = 'text';
        this.domChanges.cnfpasswordIcon = 'icofont icofont-eye';
      } else {
        this.domChanges.cnfpasswordType = 'password';
        this.domChanges.cnfpasswordIcon = 'icofont icofont-eye-blocked';

      }
    } else {
      if (this.domChanges.passwordType == 'password') {
        this.domChanges.passwordType = 'text';
        this.domChanges.passwordIcon = 'icofont icofont-eye';
      } else {
        this.domChanges.passwordType = 'password';
        this.domChanges.passwordIcon = 'icofont icofont-eye-blocked';

      }
    }
  }

}
