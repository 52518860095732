<section class="d-flex text-left bg-primary common-img-bg" *ngIf="isgotData">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="text-center logo-profile">
          <img class="img-logo" src="assets/images/hasmanlogo.svg" alt="Hasman img">
          <span class="f-right">
            <a class="btn btn-primary btn-round" [routerLink]="['/dashboard/connections/direct']">Back </a>&nbsp;&nbsp;&nbsp;
            <!-- <a class="btn btn-primary btn-round" [routerLink]="['/register/direct']">Signup</a> -->
          </span>
        </div>
        <div class="profile-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="page-body">
                  <div class="page-header card">
                  </div>
                  <!--profile cover start-->
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="cover-profile">
                        <div class="profile-bg-img">
                          <img *ngIf="userProfileList?.bgImg" class="profile-bg-img img-fluid img-bg-cus" [src]="userProfileList.bgImg | safeImgUrl"
                            alt="bg-img">
                          <img *ngIf="!userProfileList?.bgImg"  class="profile-bg-img img-fluid img-bg-cus" src="assets/images/user-profile/bg-img1.jpg"
                            alt="bg-img">
                          <div class="card-block user-info">
                            <div class="col-md-12">
                              <div class="media-left">
                                <a class="profile-image">
                                  <img *ngIf="userProfileList?.profilePic" class="user-img img-radius" [src]="userProfileList.profilePic | safeImgUrl"
                                    alt="user-img">
                                  <img *ngIf="!userProfileList?.profilePic" class="user-img img-radius" src="assets/images/noImage.png"
                                    alt="user-img">
                                </a>
                              </div>
                              <div class="media-body row">
                                <div class="col-lg-12">
                                  <div class="user-title">
                                    <h2>{{userProfileList?.firstName ? (userProfileList?.firstName+' '+userProfileList?.lastName):''}}</h2>
                                    <span class="text-white">{{userProfileList?.designation ? userProfileList?.designation:''}}</span>
                                  </div>
                                </div>
                                <div>
                                  <div class="pull-right cover-btn">
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--profile cover end-->
                <div class="card-header">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>{{userProfileList?.firstName
                        ? userProfileList?.firstName+'
                        '+userProfileList?.lastName : ''}} </h5>
                      <span class="text-muted f-14 m-b-10" [innerHTML]="userProfileList?.designation"></span>
                    </div>
                    <div class="col-md-6">
                      <span class="text-muted text-right f-14 m-b-10">{{userProfileList?.email}}<br>{{userProfileList?.mobile
                        ? ('+' +userProfileList?.mobile):''}}</span>
                    </div>
                  </div>
                </div>
                <div class="card-header">
                  <!-- //Profile summary -->
                  <h4 class="sub-title">Professional
                    Summary
                  </h4>
                  <div class="unique-margin">
                    <p *ngIf="userProfileList?.profileSummary" [innerHTML]="userProfileList?.profileSummary | safeHtml"></p>
                    <p *ngIf="!userProfileList?.profileSummary">No Data to Display</p>
                  </div>
                </div>

                <!-- Profile Skill Set -->
                <div class="card-header">
                  <h4 class="sub-title"> Skill Set
                  </h4>
                  <div class="unique-margin-ul">
                    <!-- <p *ngIf="userProfileList?._skillSets.length<1">No Data to Display</p> -->
                    <ul *ngFor="let item of userProfileList?.skillSets; let i = index;">
                      <span>
                        <h6><u>{{item?.definedSkillSets?.skillName}}</u></h6>
                        <li style="margin-left:10px" [innerHTML]="item.skillDesc | safeHtml"></li>
                      </span>
                      <br>
                    </ul>
                    <p *ngIf="!userProfileList?.skillSets?.length">No Data to Display</p>
                  </div>
                </div>

                <!-- Skills End -->

                <!-- Qualifications Start -->
                <div class="card-header">
                  <h4 class="sub-title"> Qualifications
                  </h4>
                  <div class="unique-margin-ul">
                    <!-- <p *ngIf="userProfileList?._skillSets.length<1">No Data to Display</p> -->
                    <ul *ngFor="let item of userProfileList?._qualifications; let i = index;">

                      <label><b>Qualification</b> : {{item.qualification}}</label><br>
                      <label><b>University / Board</b> : {{item.board ? item.board:'--'}}</label><br>
                      <label><b>Pass Out Year</b> : {{item.passout ? item.passout:'--'}}</label><br>
                      <label><b>Marks / Percentage</b> : {{item.marks ? item.marks:'--'}}</label><br>
                      <br>
                    </ul>
                    <p *ngIf="!userProfileList?._qualifications || userProfileList?._qualifications.length<1">No Data
                      to Display</p>
                  </div>
                </div>
                <!-- End -->

                <!-- Projects -->
                <div class="card-header">
                  <h4 class="sub-title"> Work Experience &nbsp;&nbsp;
                  </h4>
                  <div class="unique-margin-ul">
                    <ul *ngFor="let item of userProfileList?._projectLists; let i = index;">
                      <span>
                        <h6 class="project-label"><u>Project / Assignment : {{i+1}}</u></h6>
                        <div class="row">
                          <div class="col-md-9">
                            <label><b>Client</b>: {{item.client}}</label><br>
                            <label><b>Duration</b>: {{(item.from | date:'dd-MMM-yyyy')+' To '+ (item.to ? (item.to |
                              date:'dd-MMM-yyyy'):'till Date')}}</label><br>
                            <label><b>Project / Assignment Name</b>: {{item.projectName}}</label><br>
                            <label><b>Role</b>: {{item.role}}</label><br>
                          </div>
                          <div class="col-md-3" *ngIf="item.logo">
                            <img *ngIf="item.logo" style="width:100px;height:100px" [src]="item.logo | safeImgUrl" alt="bg-img">
                          </div>
                        </div>
                        <label class="project-label"><b>Project / Assignment Overview</b>:</label><br>
                        <label class="project-label-content" [innerHTML]="item.projectOverview | safeHtml"></label><br>
                        <label class="project-label"><b>Scope of work and Accountabilities</b>:</label><br>
                        <label class="project-label-content" [innerHTML]="item.scopeAndAcc | safeHtml"></label><br>
                        <label class="project-label"><b>Key Achievements</b>:</label><br>
                        <label class="project-label-content" [innerHTML]="item.keyAchievements | safeHtml"></label><br>
                      </span>
                    </ul>
                  </div>
                  <br>
                  <p *ngIf="!userProfileList?._projectLists?.length">No Data to Display</p>
                </div>

                <!-- End -->
                <!-- Employeement  Start -->
                <div class="card-header">
                  <h4 class="sub-title"> Employment History
                  </h4>
                  <div class="unique-margin-ul">
                    <!-- <p *ngIf="userProfileList?._skillSets.length<1">No Data to Display</p> -->
                    <ul *ngFor="let item of userProfileList?._empHistory; let i = index;">
                      <!-- <label><b>Employer</b>: {{item.employer}}</label><br>
                      <label><b>Designation</b>: {{item.designation}}</label><br>
                      <label><b>Duration</b>: {{(item.from | date:'dd-MMM-yyyy')+' To '+ (item.to ? (item.to |
                        date:'dd-MMM-yyyy'):'till Date')}}</label><br>
                      <br> -->
                      <label *ngIf="!item.to">Working as <b>{{item.designation}}</b> at <b>{{item.employer}}</b>  from
                        {{(item.from | date:'dd-MMM-yyyy')}} to till date</label>
                      <label *ngIf="item.to">Worked as <b>{{item.designation}} </b> at <b>{{item.employer}}</b>  from
                        {{(item.from | date:'dd-MMM-yyyy')}} to {{(item.to | date:'dd-MMM-yyyy')}}</label>
                    </ul>
                    <p *ngIf="!userProfileList._empHistory || userProfileList._empHistory.length<1">No Data to Display</p>
                  </div>
                </div>
                <!-- End -->
                <!-- Certifications  Start -->
                <div class="card-header">
                  <h4 class="sub-title"> Certifications
                  </h4>
                  <div class="unique-margin-ul">
                    <!-- <p *ngIf="userProfileList?._skillSets.length<1">No Data to Display</p> -->
                    <ul *ngFor="let item of userProfileList?._certifications; let i = index;">
                      <!-- <label><b>Ceritifcation Name</b>: {{item.name ? item.name:''}}</label><br>
                      <label><b>Ceritifcation Year</b>: {{item.year ? item.year:''}}</label><br>
                      <label><b>Ceritifcation Body</b>: {{item.body ? item.body:''}}</label><br> -->
                      <label>Certified as <b>{{item.name ? item.name:''}}</b> by {{item.body ? item.body:'--'}} in {{item.year ? item.year:''}}</label><br>

                    </ul>
                    <p *ngIf="!userProfileList?._certifications?.length">No Data to Display</p>
                  </div>
                </div>
                <!-- End -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>