<section class="login p-fixed d-flex text-center bg-primary ">
  <!-- Container-fluid starts -->
  <div class="container">
    <div class="row" *ngIf="!resetPass">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <form class="md-float-material" (ngSubmit)="pwdpattern && (resetFrm.form.valid && resetPassword())" #resetFrm="ngForm"
            novalidate>

            <div class="auth-box">
              <div class="text-center">
                <img src="assets/images/hasmanlogo.svg" alt="Hasman img">
              </div>
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-left txt-primary">Reset Password</h3>
                </div>
              </div>
              <hr />
              <div class="input-group">
                <input type="{{domChanges.passwordType}}" class="form-control" placeholder="New Password" name="password" [(ngModel)]="model.newPassword"
                  #password="ngModel" [ngClass]="{ 'is-invalid': resetFrm.submitted && password.invalid }" (input)="checkValidation($event.target.value)"
                  required style="border-right: none" >
                  <span class="input-group-addon" (click)="showHidePassword('pass')"><i style="font-size:21px;line-height:2 !important;margin-right: 34%" class="{{domChanges.passwordIcon}}"></i></span>
                <div *ngIf="(resetFrm.submitted && password.invalid)|| (password.invalid && (password.dirty || password.touched))"
                  class="invalid-feedback text-left">
                  <div *ngIf="password.errors.required">Password is required</div>
                </div>
                <div *ngIf="(resetFrm.submitted)|| ((password.dirty || password.touched))">
                  <div *ngIf="!pwdpattern" style="color:red">
                    Password contains atleast 8 characters,1 number,1 symbol and 1 uppercase letter.
                  </div>
                </div>
              </div>
              <div class="input-group">
                <input type="{{domChanges.cnfpasswordType}}" class="form-control" name="cnfPassword" placeholder="Confirm Password" [(ngModel)]="domChanges.cnfPassword"
                  #cnfPassword="ngModel" [ngClass]="{ 'is-invalid': resetFrm.submitted && cnfPassword.invalid }"
                  required style="border-right: none"  />
                  <span class="input-group-addon" (click)="showHidePassword('cnf')"><i style="font-size:21px;line-height:2 !important;margin-right: 34%" class="{{domChanges.cnfpasswordIcon}}"></i></span>
                <div *ngIf="resetFrm.submitted && cnfPassword.invalid" class="invalid-feedback text-left">
                  <div *ngIf="cnfPassword.errors.required">Confirm Password is required</div>
                </div>
                <!-- <span class="md-line"></span> -->
              </div>
              <div class="input-group" *ngIf="model.newPassword">
                <password-strength-meter class="form-control" style="border:none" [password]="model.newPassword"
                  [enableFeedback]="true"></password-strength-meter>
                <span id="passwordHelpBlock" class="form-text text-muted">
                  Your password must be 8-20 characters long, contain letters and numbers, and
                  must not contain spaces, special characters,
                  or emoji.
                </span>
                <div class="invalid-feedback">
                  Please provide a valid Password.
                </div>
                <br>
              </div>

              <div class="row m-t-30">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-success btn-md btn-block waves-effect text-center m-b-20">Reset
                    Password</button>
                </div>
              </div>
            </div>
          </form>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->

      </div>
    </div>
    <div class="row" *ngIf="resetPass">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <div class="auth-box">
            <div class="text-center">
              <img src="assets/images/hasmanlogo.svg" alt="Hasman img">
            </div>
            <div class="row m-b-20">
              <div class="col-md-12">
                <h3 class="text-center txt-primary">Thank You</h3>
                <p>Password Updated Sucessfully</p>
                <p>Please Wait while we redirect to Login Page</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of col-sm-12 -->
  </div>