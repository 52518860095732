/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./page-not-found.component";
var styles_PageNotFoundComponent = [i0.styles];
var RenderType_PageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "section", [["class", "login p-fixed d-flex text-center bg-primary common-img-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "signup-card card-block auth-body mr-auto ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "auth-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "Hasman img"], ["src", "assets/images/hasmanlogo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "row m-b-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "col-md-12 custom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "display-1 d-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "mb-4 lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The page you are looking for was not found."])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["style", "color:green;font-size: 16px"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(16, 1), (_l()(), i1.ɵted(-1, null, ["Back to Home "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 16, 0, "/dashboard/profile"); _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 15).target; var currVal_1 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_0, currVal_1); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i4.PageNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i4.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
