import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpModule } from '@angular/http';
import { SharedModule } from './shared/shared.module';
import { HttpIntercepterService } from './shared/services/http-intercepter.service';
import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuItems } from './shared/menu-items/menu-items';
import { ToastrModule } from 'ng6-toastr-notifications';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProfileService } from './shared/services/profile-services';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { QuillModule } from 'ngx-quill';
import { CompanyCreationComponent } from './pages/company-creation/company-creation.component'
import { SafeHtmlPipe } from './shared/services/safe-html-pipe';
import { SafeImgUrlPipe } from './shared/services/safe-imgUrl-pipe';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { InternationalPhoneModule } from 'ng4-intl-phone';
import { PublicProfileComponent } from './pages/public-profile/public-profile.component';
import { AdminSkllSetsComponent } from './pages/admin/admin-skll-sets/admin-skll-sets.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ConnectionsComponent } from './pages/connections/connections.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    ResetPasswordComponent,
    SafeHtmlPipe,
    SafeImgUrlPipe,
    CompanyCreationComponent,
    PublicProfileComponent,
    AdminSkllSetsComponent,
    LandingPageComponent,
    PageNotFoundComponent,
    ConnectionsComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    DataTablesModule,
    AppRoutingModule,
    SharedModule,
    HttpModule,
    AngularEditorModule,
    ToastrModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    QuillModule,
    PasswordStrengthMeterModule,
    InternationalPhoneModule,
    NgSelectModule,
    ImageCropperModule
  ],
  providers: [MenuItems, HttpIntercepterService,ProfileService],
  bootstrap: [AppComponent]
})
export class AppModule { }
