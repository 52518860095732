<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="signup-card card-block auth-body mr-auto ml-auto">
          <form class="md-float-material" (ngSubmit)="pwdpattern && (registerFrm.form.valid && register())" #registerFrm="ngForm"
            novalidate *ngIf="!registationCompleted">
            <div class="auth-box">
              <div class="text-center">
                <img src="assets/images/hasmanlogo.svg" alt="Hasman img">
              </div>
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-center txt-primary" *ngIf="!domChanges.paramsObj?.name">Sign up. It is fast and easy.</h3>
                  <h3 class="text-center txt-primary" *ngIf="domChanges.paramsObj?.name">{{domChanges.paramsObj?.name}} Invites You to Hasman</h3>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="First Name" name="fname" [(ngModel)]="model.firstName"
                      #fname="ngModel" [ngClass]="{ 'is-invalid': registerFrm.submitted && fname.invalid }" required>
                    <div *ngIf="registerFrm.submitted && fname.invalid" class="invalid-feedback text-left">
                      <div *ngIf="fname.errors.required">First Name required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Last Name" name="lname" [(ngModel)]="model.lastName"
                      #lname="ngModel" [ngClass]="{ 'is-invalid': registerFrm.submitted && lname.invalid }" required>
                    <div *ngIf="registerFrm.submitted && lname.invalid" class="invalid-feedback text-left">
                      <div *ngIf="lname.errors.required">Last Name required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Choose User Name" name="uname" [(ngModel)]="model.username"
                      #uname="ngModel" [ngClass]="{ 'is-invalid': registerFrm.submitted && uname.invalid }" required>
                    <div *ngIf="registerFrm.submitted && uname.invalid" class="invalid-feedback text-left">
                      <div *ngIf="uname.errors.required">Choose User Name </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Your Email Address" name="email" [(ngModel)]="model.email"
                      #email="ngModel" [pattern]="emailPattern" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched || registerFrm.submitted)}"
                      required [disabled]="domChanges.paramsObj?.email">
                    <div *ngIf="(registerFrm.submitted && email.invalid) || (email.invalid && (email.dirty || email.touched))"
                      class="invalid-feedback text-left">
                      <div *ngIf="email.errors.required">Email is required</div>
                      <div *ngIf="email.errors.pattern">
                        Email must be a valid email address.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group">
                    <input type="{{domChanges.passwordType}}" class="form-control" placeholder="Choose Password" name="password" [(ngModel)]="model.password"
                      #password="ngModel" (input)="checkValidation($event.target.value)" [ngClass]="{ 'is-invalid': password.invalid && (password.dirty || password.touched || registerFrm.submitted)}"
                      required style="border-right: none" >
                      <span class="input-group-addon" (click)="showHidePassword('pass')"><i style="font-size:21px;line-height:2 !important;margin-right: 24%" class="{{domChanges.passwordIcon}}"></i></span>
                    <div *ngIf="(registerFrm.submitted && password.invalid)|| (password.invalid && (password.dirty || password.touched))"
                      class="invalid-feedback text-left">
                      <div *ngIf="password.errors.required">Password required</div>
                    </div>
                    <div *ngIf="(registerFrm.submitted)|| ((password.dirty || password.touched))">
                      <div *ngIf="!pwdpattern" style="color:red">
                        Password contains atleast 8 characters,1 number,1 symbol and 1 uppercase letter.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group">
                    <input type="{{domChanges.cnfpasswordType}}" class="form-control" placeholder="Confirm Password" name="cnfpass"
                      [(ngModel)]="domChanges.cnfpass" #cnfpass="ngModel" [ngClass]="{ 'is-invalid': registerFrm.submitted && cnfpass.invalid }"
                      required style="border-right: none" >
                      <span class="input-group-addon" (click)="showHidePassword('cnf')"><i style="font-size:21px;line-height:2 !important;margin-right: 24%" class="{{domChanges.cnfpasswordIcon}}"></i></span>
                    <div *ngIf="registerFrm.submitted && cnfpass.invalid" class="invalid-feedback text-left">
                      <div *ngIf="cnfpass.errors.required">Confirm Password required </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group" *ngIf="model.password">
                <password-strength-meter class="form-control" style="border:none" [password]="model.password"
                  [enableFeedback]="true"></password-strength-meter>
                <span id="passwordHelpBlock" class="form-text text-muted">
                  Your password must be 8-20 characters long, contain letters and numbers, and
                  must not contain spaces, special characters,
                  or emoji.
                </span>
                <div class="invalid-feedback">
                  Please provide a valid Password.
                </div>
                <br>
              </div>


              <div class="row m-t-25 text-left">
                <div class="col-md-12">
                  <div class="checkbox-fade fade-in-primary">
                    <label>
                      <input type="checkbox" value="" name="termsandconditions" [(ngModel)]="model.termsandconditions"
                        #termsandconditions="ngModel">
                      <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
                      <span class="text-inverse">I read and accept <a href="javascript:">Terms & Conditions</a>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12">
                  <button type="submit" [disabled]="!model.termsandconditions" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Sign
                    up now</button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <p *ngIf="domChanges.paramsObj?.email" class="text-inverse text-center m-b-0">Already have an account ? <a style="color:#9c27b0"
                      [routerLink]="['/login',domChanges.params]">Login </a> </p>
                  <p *ngIf="!domChanges.paramsObj?.email" class="text-inverse text-center m-b-0">Already have an account ? <a style="color:#9c27b0"
                      [routerLink]="['/login/direct']">Login </a> </p>
                </div>
              </div>
            </div>
          </form>
          <div class="auth-box" *ngIf="registationCompleted">
            <div class="text-center">
              <img src="assets/images/hasmanlogo.svg" alt="Hasman img">
            </div>
            <div class="row m-b-20">
              <div class="col-md-12">
                <h3 class="text-center txt-primary">Thank You</h3>
                <p>Your Registation was sucessfully Completed</p>
                <p>Please Verify the mail and Login into your Hasman Account</p>
                <p *ngIf="!domChanges.paramsObj?.email"><a style="color:green" [routerLink]="['/login/direct']">Click here </a>to Navigate to Login Page</p>
                <p *ngIf="domChanges.paramsObj?.email"><a style="color:green" [routerLink]="['/login',domChanges.params]">Click here </a>to Navigate to Login Page</p>
              </div>
            </div>
          </div>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container-fluid -->
</section>