import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor() { }
    public _profile: any;

    setProfile(value: any) {
        this._profile = value
    }

    getProfile(): any {
        return this._profile;
    }
}
