import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { CompanyCreationComponent } from './pages/company-creation/company-creation.component';
import { PublicProfileComponent } from './pages/public-profile/public-profile.component';
import { AdminSkllSetsComponent } from './pages/admin/admin-skll-sets/admin-skll-sets.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ConnectionsComponent } from './pages/connections/connections.component';

const routes: Routes = [
  {
    path: 'login/:ref',
    component: LoginComponent
  },
  {
    path: 'register/:ref',
    component: RegisterComponent
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: 'resetPassword/:access_token',
    component: ResetPasswordComponent
  },
  {
    path: 'emailVerified',
    component: LandingPageComponent
  },
  {
    path: ':userName',
    component: PublicProfileComponent
  },
  

  {
    path: 'dashboard',
    component: AdminComponent,
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'connections/:ref',
        component: ConnectionsComponent
      },
      {
        path: 'yourCompany',
        component: CompanyCreationComponent
      },
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'skillSets',
        component: AdminSkllSetsComponent
      },
    ]
  },
  { path: '**', redirectTo: 'dashboard/profile', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
