/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./landing-page.component";
var styles_LandingPageComponent = [i0.styles];
var RenderType_LandingPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingPageComponent, data: {} });
export { RenderType_LandingPageComponent as RenderType_LandingPageComponent };
export function View_LandingPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "section", [["class", "login p-fixed d-flex text-center bg-primary common-img-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 14, "div", [["class", "signup-card card-block auth-body mr-auto ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "auth-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "Hasman img"], ["src", "assets/images/hasmanlogo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "row m-b-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h3", [["class", "text-center txt-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank You"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Account has been sucessfully Verified"])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["routerLink", "['/login']"], ["style", "color:green"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Click here "])), (_l()(), i1.ɵted(-1, null, ["to Navigate to Login Page"]))], function (_ck, _v) { var currVal_2 = "['/login']"; _ck(_v, 16, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 16).target; var currVal_1 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_0, currVal_1); }); }
export function View_LandingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing-page", [], null, null, null, View_LandingPageComponent_0, RenderType_LandingPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.LandingPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingPageComponentNgFactory = i1.ɵccf("app-landing-page", i4.LandingPageComponent, View_LandingPageComponent_Host_0, {}, {}, []);
export { LandingPageComponentNgFactory as LandingPageComponentNgFactory };
