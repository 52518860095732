<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="signup-card card-block auth-body mr-auto ml-auto">
          <div class="auth-box">
            <div class="text-center">
              <img src="assets/images/hasmanlogo.svg" alt="Hasman img">
            </div>
            <div class="row m-b-20">
              <div class="col-md-12 custom">
                <span class="display-1 d-block">404</span>
                <div class="mb-4 lead">The page you are looking for was not found.</div>
                <a style="color:green;font-size: 16px" [routerLink]="['/dashboard/profile']">Back to Home </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>