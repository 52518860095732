import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Injectable({
  providedIn: 'root'
})
export class HttpIntercepterService {
  //Service URL
  private _baseURL = environment.url;
  private auth = localStorage.getItem('myToken');
  private id = localStorage.getItem('id');
  constructor(private _http: Http, public toastr: ToastrManager, private route: Router, private spinnerService: Ng4LoadingSpinnerService,private activatedRoute: ActivatedRoute) { }

  /// Basic APIs
  login(url: any, body: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.post(this._baseURL + '/' + url, body, { headers: headers })
      .catch((e: any) => Observable.throw(this.errorHandler(e)))
      .pipe(map((response: any) => response.json()));
  }
  register(url: any, body: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.post(this._baseURL + '/' + url, body, { headers: headers })
      .catch((e: any) => Observable.throw(this.errorHandler(e)))
      .pipe(map((response: any) => response.json()));
  }
  resetPassword(url: any, body: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.post(this._baseURL + '/' + url + `?access_token=${body.token}`, body, { headers: headers })
      .catch((e: any) => Observable.throw(this.errorHandler(e)))
      .pipe(map((response: any) => response.json()));
  }
  //End///

  getDataFromServer(url: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.get(url, { headers: headers })
      .catch((e: any) => Observable.throw(this.errorHandler(e)))
      .pipe(map(
        (response: any) => response.json()),
      )
  }

  postDataToserver(url: any, body: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.post(url, body, { headers: headers })
      .catch((e: any) => Observable.throw(this.errorHandler(e)))
      .pipe(map((response: any) => response.json()));
  }
  updateDataToServer(url: any, body: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.patch(url, body, { headers: headers })
      .catch((e: any) => Observable.throw(this.errorHandler(e)))
      .pipe(map((response: any) => response.json()));
  }
  nestedObjectsUpdate(url: any, body: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.put(url, body, { headers: headers })
      .catch((e: any) => Observable.throw(this.errorHandler(e)))
      .pipe(map((response: any) => response.json()));
  }
  deleteDataFromServer(url: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.delete(url, { headers: headers })
      .catch((e: any) => Observable.throw(this.errorHandler(e)))
      .pipe(map((response: any) => response.json()));
  }

  errorHandler(error: any): void {
    if (error.status == 404) {
      this.route.navigate(['/page-not-found']);
      this.spinnerService.hide();
      return;
    }
    if (error.status == 500) {
      this.route.navigate(['/page-not-found']);
      this.spinnerService.hide();
      return;
    }
    if (error.type == 3) {
      this.toastr.errorToastr("Unable to Connect Server", 'Oops!');
      this.spinnerService.hide();
    } else if (error.type == 2) {
      if (typeof error._body == "string") {
        this.spinnerService.hide();
        let err = JSON.parse(error._body);
        this.toastr.errorToastr(err.error.message, 'Oops!');
        if (err.error.statusCode == 401) {
          localStorage.clear();
          this.activatedRoute.params.subscribe(params => {
            if (params.ref == "direct") {
              this.route.navigate(['/login/direct']);
            }
          });
        }
      } else {
        this.spinnerService.hide();
        if (error.statusCode == 401) {
          localStorage.clear();
          this.activatedRoute.params.subscribe(params => {
            if (params.ref == "direct") {
              this.route.navigate(['/login/direct']);
            }
          });
        }
      }
    }
  }
}
