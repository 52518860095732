import { Component, OnInit, ViewEncapsulation, Pipe, PipeTransform, NgModuleRef } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbDatepickerConfig, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { HttpIntercepterService } from 'src/app/shared/services/http-intercepter.service';
import { ProfileService } from 'src/app/shared/services/profile-services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ],
})
export class ProfileComponent implements OnInit {
  projectEdited: boolean;
  dateObj: any = {};
  closeResult: string;
  profileBuilderData: any = {};
  private _baseURL = environment.url;
  buildProfile: any;
  private auth = localStorage.getItem('myToken');
  private id = localStorage.getItem('id');
  qualificationMdl: any = {};
  profileMdl: any;
  skillsSetMdl: any = {};
  projectsMdl: any = {};
  domChanges: any = {
    datesList1: [],
    datesList2: [],
    empHisDateList1: [],
    empHisDateList2: []
  };
  skillSetList = [];
  projectList = [];
  qualificationList = [];
  empHistoryList = [];
  certificationList = [];
  skillSetEdited: any;
  empMdl: any = {};
  certificationMdl: any = {};
  definedSkillsList: any = [];
  secMailsList: any = [];
  public loading = false;
  public profileBuilder: any = {};
  fileName: string;
  filePreview: string;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  pwdpattern = true;
  maxDate: any;




  imageChangedEvent: any = {};
  imageChangedEventBg: any = {};
  imageChangedEventClient: any = {};

  constructor(private http: HttpIntercepterService, public profile: ProfileService, public toastr: ToastrManager, private spinnerService: Ng4LoadingSpinnerService, private route: Router, config: NgbDatepickerConfig, calendar: NgbCalendar, private select: NgSelectConfig, private modalService: NgbModal) {
    config.minDate = { year: 1950, month: 1, day: 1 };
    config.maxDate = { year: 2050, month: 12, day: 31 };
    this.select.notFoundText = 'No results Found';

  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }

  ngOnInit() {
    this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.spinnerService.show();
    setTimeout(() => {
      this.profileMdl = Object.assign({}, this.profile.getProfile());
      this.spinnerService.hide();
      if (this.profileMdl.mobile) {
        this.profileMdl.mobile = this.profileMdl.mobile.toString();
        let str = this.profileMdl.mobile[0];
        if (str != '+') {
          this.profileMdl.mobile = '+' + this.profileMdl.mobile;
        }
      }
      if (this.profileMdl && this.profileMdl.dob) {
        this.profileMdl.dob = new Date(this.profileMdl.dob);
        this.dateObj.dob = { year: this.profileMdl.dob.getFullYear(), month: this.profileMdl.dob.getMonth() + 1, day: this.profileMdl.dob.getDate() };
      }
    }, 2000);
    if (this.auth) {
      this.getBuildProfileDetails();
      this.getDefinedSkills();
      this.getSecMails();
    }

  }
  updateProfile() {
    this.spinnerService.show();
    if (this.dateObj.dob) {
      this.profileMdl.dob = new Date(this.dateObj.dob.month + ' ' + this.dateObj.dob.day + ' ' + this.dateObj.dob.year);
    }
    if (this.profileMdl.mobile) {
      let str = this.profileMdl.mobile[0];
      if (str != '+') {
        this.profileMdl.mobile = '+' + this.profileMdl.mobile;
      }
    }
    this.profileMdl._isDeleted = false;
    this.http.updateDataToServer(this._baseURL + '/Accounts' + `/${this.id}?access_token=${this.auth}`, this.profileMdl).subscribe(
      res => {
        this.spinnerService.hide();
        this.profile.setProfile(this.profileMdl);
        this.domChanges.profiledescEdited = false;
        this.domChanges.editUser = false;
        this.domChanges.profileNameEdited = false;
      })
  }

  ///API CALL FOR BUILD PROFILE
  getBuildProfileDetails() {
   
    this.spinnerService.show();
    this.http.getDataFromServer(this._baseURL + '/profile/getProfile?id=' + `${this.id}&access_token=${this.auth}`).subscribe(
      res => {
        this.spinnerService.hide();
        if (res.data.length) {
          this.profileBuilderData = res.data[0];
          this.profileBuilder.profileSummary = res.data[0].profileSummary;
          this.profileBuilder.isPublic = res.data[0].isPublic ? res.data[0].isPublic : false;
          this.profileBuilder.proffessionalQualifications = res.data[0].proffessionalQualifications;
          this.profileBuilder.empHistory = res.data[0].empHistory;
          this.skillSetList = res.data[0].skillSets;
          this.projectList = res.data[0]._projectLists;
          this.qualificationList = res.data[0]._qualifications;
          this.empHistoryList = res.data[0]._empHistory;
          this.certificationList = res.data[0]._certifications;

          this.domChanges.datesList1 = [];
          this.domChanges.datesList2 = [];
          this.domChanges.empHisDateList1 = [];
          this.domChanges.empHisDateList2 = [];

          /// Logic For Sort Object PROJECT LIST
          for (var k = 0; k < this.projectList.length; k++) {
            if (this.projectList[k].to) {
              this.domChanges.datesList1.push(this.projectList[k])
            } else {
              this.domChanges.datesList2.push(this.projectList[k])
            }
          }
          this.domChanges.datesList1 = this.sortDates(this.domChanges.datesList1, false);
          this.domChanges.datesList2 = this.sortDates(this.domChanges.datesList2, true);
          this.projectList = this.domChanges.datesList2;
          for (var p = 0; p < this.domChanges.datesList1.length; p++) {
            this.projectList[this.projectList.length] = this.domChanges.datesList1[p]
          }

          /// Logic For Sort Object EMP HIS
          for (var k = 0; k < this.empHistoryList.length; k++) {
            if (this.empHistoryList[k].to) {
              this.domChanges.empHisDateList1.push(this.empHistoryList[k])
            } else {
              this.domChanges.empHisDateList2.push(this.empHistoryList[k])
            }
          }
          this.domChanges.empHisDateList1 = this.sortDates(this.domChanges.empHisDateList1, false);
          this.domChanges.empHisDateList2 = this.sortDates(this.domChanges.empHisDateList2, true);
          this.empHistoryList = this.domChanges.empHisDateList2;
          for (var p = 0; p < this.domChanges.empHisDateList1.length; p++) {
            this.empHistoryList[this.empHistoryList.length] = this.domChanges.empHisDateList1[p]
          }
        }
      })
  }

  sortDates(item, to) {
    if (to) {
      return item.sort((a, b) => {
        if (new Date(b.from) != new Date(a.from)) {
          return <any>new Date(b.from) - <any>new Date(a.from);
        }
      });
    } else {
      return item.sort((a, b) => {
        if (new Date(b.from) != new Date(a.from)) {
          return <any>new Date(b.from) - <any>new Date(a.from);
        } else {
          return <any>new Date(a.to) - <any>new Date(b.to);
        }
      });
    }
  }

  saveorUpdateBuildProfile(model: any) {
    this.spinnerService.show();
    if (this.profileBuilderData.id) {
      model.id = this.profileBuilderData.id;
      delete model._isDeleted;
      this.http.updateDataToServer(this._baseURL + '/profile' + `?access_token=${this.auth}`, model).subscribe(
        res => {
          this.getBuildProfileDetails();
          this.resetDOM()
          this.spinnerService.hide();
        })
    } else {
      this.profileBuilder.accountId = localStorage.getItem('id');
      this.http.postDataToserver(this._baseURL + '/profile' + `?access_token=${this.auth}`, this.profileBuilder).subscribe(
        res => {
          this.getBuildProfileDetails();
          this.resetDOM()
          this.spinnerService.hide();
        })
    }
  }

  addorUpdateSkillSet() {
    this.spinnerService.show();
    if (this.skillsSetMdl.skillName) {
      delete this.skillsSetMdl.skillName;
    }
    if (this.profileBuilderData.id) {
      if (!this.domChanges.skillEdit) {
        if (this.skillsSetMdl.id) {
          this.skillsSetMdl.skillId = this.skillsSetMdl.id;
          delete this.skillsSetMdl.id;
        }
        this.http.postDataToserver(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/skillSets?access_token=${this.auth}`, this.skillsSetMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.skillsSetMdl = {};
            this.resetDOM();
          })
      } else {
        this.http.nestedObjectsUpdate(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/skillSets/${this.skillsSetMdl.id}?access_token=${this.auth}`, this.skillsSetMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.skillsSetMdl = {};
            this.resetDOM();
            this.domChanges.skillEdit = false;
          })

      }
    }
    else {
      // this.skillsSetMdl.accountId = localStorage.getItem('id');
      if (this.skillsSetMdl.id) {
        this.skillsSetMdl.skillId = this.skillsSetMdl.id;
        delete this.skillsSetMdl.id;
      }
      this.http.nestedObjectsUpdate(this._baseURL + '/profile' + `?access_token=${this.auth}`, { accountId: localStorage.getItem('id'), _skillSets: [this.skillsSetMdl] }).subscribe(
        res => {
          this.getBuildProfileDetails();
          this.resetDOM()
          this.spinnerService.hide();
        })
    }
  }
  addorUpdateProject() {
    if (this.projectsMdl.from && this.projectsMdl.to) {
      let from = new Date(this.projectsMdl.from.month + ' ' + this.projectsMdl.from.day + ' ' + this.projectsMdl.from.year);
      let to = new Date(this.projectsMdl.to.month + ' ' + this.projectsMdl.to.day + ' ' + this.projectsMdl.to.year);
      if (new Date(from) > new Date(to)) {
        this.toastr.errorToastr('Project Start Should be Less than Project End Date');
        return;
      }
    }
    if (!this.projectsMdl.from) {
      this.toastr.errorToastr('Project Start Date is required !');
      return;
    }
    this.spinnerService.show();
    if (this.profileBuilderData.id) {
      if (!this.domChanges.projectEdit) {
        if (this.projectsMdl.from) {
          this.projectsMdl.from = new Date(this.projectsMdl.from.month + ' ' + this.projectsMdl.from.day + ' ' + this.projectsMdl.from.year);
        }
        if (this.projectsMdl.to) {
          this.projectsMdl.to = new Date(this.projectsMdl.to.month + ' ' + this.projectsMdl.to.day + ' ' + this.projectsMdl.to.year);
        }
        this.http.postDataToserver(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/projectLists?access_token=${this.auth}`, this.projectsMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.projectsMdl = {};
            this.resetDOM();
          })
      } else {
        if (this.projectsMdl.from) {
          this.projectsMdl.from = new Date(this.projectsMdl.from.month + ' ' + this.projectsMdl.from.day + ' ' + this.projectsMdl.from.year);
        }
        if (this.projectsMdl.to) {
          this.projectsMdl.to = new Date(this.projectsMdl.to.month + ' ' + this.projectsMdl.to.day + ' ' + this.projectsMdl.to.year);
        }
        this.http.nestedObjectsUpdate(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/projectLists/${this.projectsMdl.id}?access_token=${this.auth}`, this.projectsMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.projectsMdl = {};
            this.resetDOM();
            this.domChanges.projectEdit = false;
          })
      }
    } else {
      if (this.projectsMdl.from) {
        this.projectsMdl.from = new Date(this.projectsMdl.from.month + ' ' + this.projectsMdl.from.day + ' ' + this.projectsMdl.from.year);
      }
      if (this.projectsMdl.to) {
        this.projectsMdl.to = new Date(this.projectsMdl.to.month + ' ' + this.projectsMdl.to.day + ' ' + this.projectsMdl.to.year);
      }
      this.http.nestedObjectsUpdate(this._baseURL + '/profile' + `?access_token=${this.auth}`, { accountId: localStorage.getItem('id'), _projectLists: [this.projectsMdl] }).subscribe(
        res => {
          this.getBuildProfileDetails();
          this.resetDOM()
          this.spinnerService.hide();
        })
    }
  }
  editSkill(item: any, index: number) {
    this.skillsSetMdl = Object.assign({}, item);
    this.domChanges.skillEdit = true;
    this.domChanges.skillSetEdited = true;
    this.domChanges.index = index;
    this.domChanges.skillId = item.skillId;
  }
  deleteSkill(item: any, ind: number) {
    if (confirm('Are you sure,do you want to delete  ' + (item.definedSkillSets ? item.definedSkillSets.skillName : '') + '  Skill ?')) {
      this.spinnerService.show();
      this.http.deleteDataFromServer(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/skillSets/${item.id}?access_token=${this.auth}`).subscribe(
        res => {
          this.spinnerService.hide();
          this.skillSetList.splice(ind, 1);
          this.resetDOM();
        })
    }
  }

  //Projects
  editProject(item: any, index: number) {
    this.projectsMdl = Object.assign({}, item);
    this.projectsMdl.from = new Date(this.projectsMdl.from);
    if (this.projectsMdl.to) {
      this.projectsMdl.to = new Date(this.projectsMdl.to);
      this.projectsMdl.to = { year: this.projectsMdl.to.getFullYear(), month: this.projectsMdl.to.getMonth() + 1, day: this.projectsMdl.to.getDate() };
    }
    this.projectsMdl.from = { year: this.projectsMdl.from.getFullYear(), month: this.projectsMdl.from.getMonth() + 1, day: this.projectsMdl.from.getDate() };
    this.domChanges.projectEdit = true;
    this.domChanges.projectEdited = true;
    this.domChanges.projectIndex = index;
  }
  deleteProject(item: any, ind: number) {
    if (confirm('Are you sure,do you want to delete  ' + item.projectName + '  Project ?')) {
      this.spinnerService.show();
      this.http.deleteDataFromServer(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/projectLists/${item.id}?access_token=${this.auth}`).subscribe(
        res => {
          this.spinnerService.hide();
          this.projectList.splice(ind, 1);
        })
      this.skillSetList.splice(ind, 1);
    }
  }


  //Qualifications Start////
  addorUpdateQualification() {
    this.spinnerService.show();
    if (this.profileBuilderData.id) {
      if (!this.domChanges.qualificationEdit) {
        this.http.postDataToserver(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/qualifications?access_token=${this.auth}`, this.qualificationMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.qualificationMdl = {};
            this.resetDOM();
          })
      } else {
        this.http.nestedObjectsUpdate(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/qualifications/${this.qualificationMdl.id}?access_token=${this.auth}`, this.qualificationMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.qualificationMdl = {};
            this.resetDOM();
            this.domChanges.qualificationEdit = false;
          })

      }
    }
    else {
      this.http.nestedObjectsUpdate(this._baseURL + '/profile' + `?access_token=${this.auth}`, { accountId: localStorage.getItem('id'), _qualifications: [this.qualificationMdl] }).subscribe(
        res => {
          this.getBuildProfileDetails();
          this.resetDOM()
          this.spinnerService.hide();
        })
    }

  }
  editQualification(item: any) {
    this.qualificationMdl = Object.assign({}, item);
    this.domChanges.qualificationEdit = true;
    this.domChanges.profileQualificationEdited = true;
  }
  deleteQualification(item: any, ind: number) {
    if (confirm('Are you sure,do you want to delete  ' + item.qualification + ' Qualification ?')) {
      this.spinnerService.show();
      this.http.deleteDataFromServer(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/qualifications/${item.id}?access_token=${this.auth}`).subscribe(
        res => {
          this.spinnerService.hide();
          this.qualificationList.splice(ind, 1);
          this.resetDOM();
        })
    }
  }


  /// End ///

  //Employeement His Start////
  addorUpdateEmpHistory() {
    if (this.empMdl.from && this.empMdl.to) {
      let from = new Date(this.empMdl.from.month + ' ' + this.empMdl.from.day + ' ' + this.empMdl.from.year);
      let to = new Date(this.empMdl.to.month + ' ' + this.empMdl.to.day + ' ' + this.empMdl.to.year);
      if (new Date(from) > new Date(to)) {
        this.toastr.errorToastr('Employeement Start Should be Less than Employeement End Date');
        return;
      }
    }
    this.spinnerService.show();
    if (this.profileBuilderData.id) {
      if (!this.domChanges.empHistoryEdit) {
        if (this.empMdl.from) {
          this.empMdl.from = new Date(this.empMdl.from.month + ' ' + this.empMdl.from.day + ' ' + this.empMdl.from.year);
        }
        if (this.empMdl.to) {
          this.empMdl.to = new Date(this.empMdl.to.month + ' ' + this.empMdl.to.day + ' ' + this.empMdl.to.year);
        }
        this.http.postDataToserver(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/empHistory?access_token=${this.auth}`, this.empMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.empMdl = {};
            this.resetDOM();
          })
      } else {
        if (this.empMdl.from) {
          this.empMdl.from = new Date(this.empMdl.from.month + ' ' + this.empMdl.from.day + ' ' + this.empMdl.from.year);
        }
        if (this.empMdl.to) {
          this.empMdl.to = new Date(this.empMdl.to.month + ' ' + this.empMdl.to.day + ' ' + this.empMdl.to.year);
        }
        this.http.nestedObjectsUpdate(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/empHistory/${this.empMdl.id}?access_token=${this.auth}`, this.empMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.empMdl = {};
            this.resetDOM();
            this.domChanges.empHistoryEdit = false;
          })

      }
    }
    else {
      if (this.empMdl.from) {
        this.empMdl.from = new Date(this.empMdl.from.month + ' ' + this.empMdl.from.day + ' ' + this.empMdl.from.year);
      }
      if (this.empMdl.to) {
        this.empMdl.to = new Date(this.empMdl.to.month + ' ' + this.empMdl.to.day + ' ' + this.empMdl.to.year);
      }
      this.http.nestedObjectsUpdate(this._baseURL + '/profile' + `?access_token=${this.auth}`, { accountId: localStorage.getItem('id'), _empHistory: [this.empMdl] }).subscribe(
        res => {
          this.getBuildProfileDetails();
          this.resetDOM()
          this.spinnerService.hide();
        })
    }

  }
  editEmpHistory(item: any) {
    this.empMdl = Object.assign({}, item);
    if (this.empMdl.to) {
      this.empMdl.to = new Date(this.empMdl.to);
      this.empMdl.to = { year: this.empMdl.to.getFullYear(), month: this.empMdl.to.getMonth() + 1, day: this.empMdl.to.getDate() };
    }
    if (this.empMdl.from) {
      this.empMdl.from = new Date(this.empMdl.from);
      this.empMdl.from = { year: this.empMdl.from.getFullYear(), month: this.empMdl.from.getMonth() + 1, day: this.empMdl.from.getDate() };
    }
    this.domChanges.empHistoryEdit = true;
    this.domChanges.employementHistoryEdited = true;
  }
  deleteEmpHistory(item: any, ind: number) {
    if (confirm('Are you sure,do you want to delete  ' + item.employer + ' Employement ?')) {
      this.spinnerService.show();
      this.http.deleteDataFromServer(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/empHistory/${item.id}?access_token=${this.auth}`).subscribe(
        res => {
          this.spinnerService.hide();
          this.empHistoryList.splice(ind, 1);
          this.resetDOM();
        })
    }
  }


  /// End ///


  //Certification  Start////
  addorUpdateCertification() {
    this.spinnerService.show();
    if (this.profileBuilderData.id) {
      if (!this.domChanges.certificationsEdit) {
        this.http.postDataToserver(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/certifications?access_token=${this.auth}`, this.certificationMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.certificationMdl = {};
            this.resetDOM();
          })
      } else {
        this.http.nestedObjectsUpdate(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/certifications/${this.certificationMdl.id}?access_token=${this.auth}`, this.certificationMdl).subscribe(
          res => {
            this.spinnerService.hide();
            this.getBuildProfileDetails();
            this.certificationMdl = {};
            this.resetDOM();
            this.domChanges.certificationsEdit = false;
          })

      }
    }
    else {
      this.http.nestedObjectsUpdate(this._baseURL + '/profile' + `?access_token=${this.auth}`, { accountId: localStorage.getItem('id'), _certifications: [this.certificationMdl] }).subscribe(
        res => {
          this.getBuildProfileDetails();
          this.resetDOM()
          this.spinnerService.hide();
        })
    }

  }
  editCertification(item: any) {
    this.certificationMdl = Object.assign({}, item);
    this.domChanges.certificationsEdit = true;
    this.domChanges.certificationsEdited = true;
  }
  deleteCertification(item: any, ind: number) {
    if (confirm('Are you sure,do you want to delete  ' + item.name + ' Certification ?')) {
      this.spinnerService.show();
      this.http.deleteDataFromServer(this._baseURL + '/profile/' + `${this.profileBuilderData.id}/certifications/${item.id}?access_token=${this.auth}`).subscribe(
        res => {
          this.spinnerService.hide();
          this.certificationList.splice(ind, 1);
          this.resetDOM();
        })
    }
  }


  /// End ///



  resetDOM() {
    this.domChanges = {}
  }
  /// END ///

  onFileChanged(event: any, type: any) {
    let reader: any = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      var file = event.target.files[0];
      if (file.name) {
        var fType = file.name.split('.');
      }
      if (type == 'bgImg') {
        this.domChanges.savedEventBg = event;
        this.domChanges.errorbgImgFileFormat = false;
        if (fType[fType.length - 1] != 'png' && fType[fType.length - 1] != 'jpg' && fType[fType.length - 1] != 'jpeg') {
          this.toastr.errorToastr('', 'Invalid File Format, Please Upload Image files Only');
          this.domChanges.errorbgImgFileFormat = true;
          return;
        }
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileName = file.name + " " + file.type;
          this.profileMdl.bgImg = 'data:image/png' + ';base64,' + reader.result.split(',')[1];
        };
      }
      else if (type == 'profile') {
        this.domChanges.savedEventProfile = event;
        this.domChanges.errorFileFormat = false;
        if (fType[fType.length - 1] != 'png' && fType[fType.length - 1] != 'jpg' && fType[fType.length - 1] != 'jpeg') {
          this.toastr.errorToastr('', 'Invalid File Format, Please Upload Image files Only');
          this.domChanges.errorFileFormat = true;
          return;
        }
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileName = file.name + " " + file.type;
          this.filePreview = 'data:image/png' + ';base64,' + reader.result.split(',')[1];
          this.profileMdl.profilePic = this.filePreview;
        };
      }
      else {
        this.domChanges.savedEventClient = event;
        this.domChanges.errorClientLogoFormat = false;
        if (fType[fType.length - 1] != 'png' && fType[fType.length - 1] != 'jpg' && fType[fType.length - 1] != 'jpeg') {
          this.toastr.errorToastr('', 'Invalid File Format, Please Upload Image files Only');
          this.domChanges.errorClientLogoFormat = true;
          return;
        }
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileName = file.name + " " + file.type;
          this.filePreview = 'data:image/png' + ';base64,' + reader.result.split(',')[1];
          this.projectsMdl.logo = this.filePreview;
        };
      }
    }
  }

  //// Skills Search and Add ///
  getDefinedSkills() {
    this.spinnerService.show();
    this.http.getDataFromServer(this._baseURL + '/DefinedSkillSets' + `?access_token=${this.auth}`).subscribe(
      res => {
        this.spinnerService.hide();
        if (res && res.length) {
          this.definedSkillsList = res;
        }
      })
  }


  onAddSkillToList(item: any) {
    if (!item.id) {
      this.http.postDataToserver(this._baseURL + '/DefinedSkillSets/' + `?access_token=${this.auth}`, { skillName: item.skillName || item, others: true }).subscribe(res => {
        this.definedSkillsList.push(res);
        this.skillsSetMdl.skillId = res.id;
        this.skillsSetMdl.skillName = res.skillName;
      })
    } else {
      this.skillsSetMdl.skillId = item.id;
    }
  }

  loadImageFailed() {
    this.toastr.errorToastr('Image Loaded Failed');
  }
  imageCropped(event: any) {
    this.profileMdl.profilePic = event.base64;
  }
  imageCroppedBg(event: any) {
    this.profileMdl.bgImg = event.base64;
  }
  imageCroppedClient(event: any) {
    this.projectsMdl.logo = event.base64;
  }
  imageLoaded() {
    // this.imageChangedEvent = this.domChanges.savedEvent;
    console.log('image Loaded');
  }

  open(content, type: any) {
    if (type == 'profile') {
      if (this.domChanges.savedEventProfile) {
        this.imageChangedEvent = this.domChanges.savedEventProfile;
        this.modalService.open(content, { centered: true });
      } else {
        this.toastr.errorToastr('Image is already cropped, Please Select Original Image to Crop');
      }
    } else if (type == 'client') {
      if (this.domChanges.savedEventClient) {
        this.imageChangedEventClient = this.domChanges.savedEventClient;
        this.modalService.open(content, { centered: true });
      } else {
        this.toastr.errorToastr('Image is already cropped, Please Select Original Image to Crop');
      }
    }
  }
  openbg(content: any) {
    if (this.domChanges.savedEventBg) {
      this.imageChangedEventBg = this.domChanges.savedEventBg;
      this.modalService.open(content, { centered: true });
    } else {
      this.toastr.errorToastr('Image is already cropped, Please Select Original Image to Crop');
    }
  }

  deleteAccount() {
    if (confirm('Are you sure,do you want to delete your Hasman Account ?')) {
      this.spinnerService.show();
      this.http.postDataToserver(this._baseURL + '/Accounts/deleteAccount' + `?access_token=${this.auth}`, { accountId: this.id }).subscribe(
        res => {
          // this.http.postDataToserver(this._baseURL + '/Accounts/logout' + `?access_token=${this.auth}`, {}).subscribe(
          //   res => {

          //   })
          this.spinnerService.hide();
          this.toastr.successToastr('Your Hasman Account Sucessfully Deleted!  Bye Bye!!');
          localStorage.clear();
          this.route.navigate(['/login/direct']);
        })
    }
  }
  getSecMails() {
    this.spinnerService.show();
    this.http.getDataFromServer(this._baseURL + '/Accounts/' + `${this.id}/userEmails?access_token=${this.auth}`).subscribe(
      res => {
        this.spinnerService.hide();
        this.secMailsList = [];
        if (res && res.length) {
          this.secMailsList = res;
        }
      })
  }
  verifySecMail(item: any) {
    if (item.email) {
      this.spinnerService.show();
      this.http.postDataToserver(this._baseURL + '/Accounts/verifyEmail' + `?access_token=${this.auth}`, { id: item.id }).subscribe(res => {
        this.spinnerService.hide();
        this.toastr.successToastr('Verification Mail Sucessfully Sent to Your E-Mail');
        this.getSecMails();
      })
    }
  }
  saveMoreEmails() {
    if (this.domChanges.moreEmails) {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (reg.test(this.domChanges.moreEmails) == false) {
        this.toastr.errorToastr('Invalid Email Address');
        return;
      }
      this.spinnerService.show();
      this.http.postDataToserver(this._baseURL + '/Accounts/' + `${this.id}/userEmails?access_token=${this.auth}`, { email: this.domChanges.moreEmails }).subscribe(res => {
        this.spinnerService.hide();
        this.toastr.successToastr('Email Added Sucessfully');
        this.domChanges.moreEmails = '';
        this.getSecMails();
      })
    }
  }
  openSecEmailPopUp(content: any) {
    this.modalService.open(content, { centered: true });
  }
  openAddSecEmailPopUp(content: any) {
    this.modalService.open(content, { centered: true });
  }
}
