import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "ng6-toastr-notifications";
import * as i3 from "@angular/router";
import * as i4 from "ng4-loading-spinner";
var HttpIntercepterService = /** @class */ (function () {
    function HttpIntercepterService(_http, toastr, route, spinnerService, activatedRoute) {
        this._http = _http;
        this.toastr = toastr;
        this.route = route;
        this.spinnerService = spinnerService;
        this.activatedRoute = activatedRoute;
        //Service URL
        this._baseURL = environment.url;
        this.auth = localStorage.getItem('myToken');
        this.id = localStorage.getItem('id');
    }
    /// Basic APIs
    HttpIntercepterService.prototype.login = function (url, body) {
        var _this = this;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.post(this._baseURL + '/' + url, body, { headers: headers })
            .catch(function (e) { return Observable.throw(_this.errorHandler(e)); })
            .pipe(map(function (response) { return response.json(); }));
    };
    HttpIntercepterService.prototype.register = function (url, body) {
        var _this = this;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.post(this._baseURL + '/' + url, body, { headers: headers })
            .catch(function (e) { return Observable.throw(_this.errorHandler(e)); })
            .pipe(map(function (response) { return response.json(); }));
    };
    HttpIntercepterService.prototype.resetPassword = function (url, body) {
        var _this = this;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.post(this._baseURL + '/' + url + ("?access_token=" + body.token), body, { headers: headers })
            .catch(function (e) { return Observable.throw(_this.errorHandler(e)); })
            .pipe(map(function (response) { return response.json(); }));
    };
    //End///
    HttpIntercepterService.prototype.getDataFromServer = function (url) {
        var _this = this;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.get(url, { headers: headers })
            .catch(function (e) { return Observable.throw(_this.errorHandler(e)); })
            .pipe(map(function (response) { return response.json(); }));
    };
    HttpIntercepterService.prototype.postDataToserver = function (url, body) {
        var _this = this;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.post(url, body, { headers: headers })
            .catch(function (e) { return Observable.throw(_this.errorHandler(e)); })
            .pipe(map(function (response) { return response.json(); }));
    };
    HttpIntercepterService.prototype.updateDataToServer = function (url, body) {
        var _this = this;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.patch(url, body, { headers: headers })
            .catch(function (e) { return Observable.throw(_this.errorHandler(e)); })
            .pipe(map(function (response) { return response.json(); }));
    };
    HttpIntercepterService.prototype.nestedObjectsUpdate = function (url, body) {
        var _this = this;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.put(url, body, { headers: headers })
            .catch(function (e) { return Observable.throw(_this.errorHandler(e)); })
            .pipe(map(function (response) { return response.json(); }));
    };
    HttpIntercepterService.prototype.deleteDataFromServer = function (url) {
        var _this = this;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.delete(url, { headers: headers })
            .catch(function (e) { return Observable.throw(_this.errorHandler(e)); })
            .pipe(map(function (response) { return response.json(); }));
    };
    HttpIntercepterService.prototype.errorHandler = function (error) {
        var _this = this;
        if (error.status == 404) {
            this.route.navigate(['/page-not-found']);
            this.spinnerService.hide();
            return;
        }
        if (error.status == 500) {
            this.route.navigate(['/page-not-found']);
            this.spinnerService.hide();
            return;
        }
        if (error.type == 3) {
            this.toastr.errorToastr("Unable to Connect Server", 'Oops!');
            this.spinnerService.hide();
        }
        else if (error.type == 2) {
            if (typeof error._body == "string") {
                this.spinnerService.hide();
                var err = JSON.parse(error._body);
                this.toastr.errorToastr(err.error.message, 'Oops!');
                if (err.error.statusCode == 401) {
                    localStorage.clear();
                    this.activatedRoute.params.subscribe(function (params) {
                        if (params.ref == "direct") {
                            _this.route.navigate(['/login/direct']);
                        }
                    });
                }
            }
            else {
                this.spinnerService.hide();
                if (error.statusCode == 401) {
                    localStorage.clear();
                    this.activatedRoute.params.subscribe(function (params) {
                        if (params.ref == "direct") {
                            _this.route.navigate(['/login/direct']);
                        }
                    });
                }
            }
        }
    };
    HttpIntercepterService.ngInjectableDef = i0.defineInjectable({ factory: function HttpIntercepterService_Factory() { return new HttpIntercepterService(i0.inject(i1.Http), i0.inject(i2.ToastrManager), i0.inject(i3.Router), i0.inject(i4.Ng4LoadingSpinnerService), i0.inject(i3.ActivatedRoute)); }, token: HttpIntercepterService, providedIn: "root" });
    return HttpIntercepterService;
}());
export { HttpIntercepterService };
