import { Component, OnInit } from '@angular/core';
import { HttpIntercepterService } from 'src/app/shared/services/http-intercepter.service';
import { ProfileService } from 'src/app/shared/services/profile-services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent implements OnInit {

  constructor(private http: HttpIntercepterService, public toastr: ToastrManager, private spinnerService: Ng4LoadingSpinnerService, private route: Router, private activatedRoute: ActivatedRoute, public profile: ProfileService) { }
  domChanges: any = {
    paramsObj: {},
    profile: {}
  };
  sentInvitationList: any = [];
  requestToMeList: any = [];
  myConncetionsList: any = [];
  private _baseURL = environment.url;
  private auth = localStorage.getItem('myToken');
  private id = localStorage.getItem('id');
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.domChanges.params = params.ref;
      if (this.domChanges.params != "direct") {
        let paramsArr = this.domChanges.params.split(',');
        this.domChanges.paramsObj.id = paramsArr[0];
        this.domChanges.paramsObj.email = paramsArr[1];
        this.domChanges.paramsObj.name = paramsArr[2];
        this.spinnerService.show();
        setTimeout(() => {
          this.domChanges.profile = this.profile.getProfile();
          if (this.domChanges.paramsObj.email == localStorage.getItem('email')) {
            this.http.updateDataToServer(this._baseURL + '/invitedMails/' + `${this.domChanges.paramsObj.id}?access_token=${this.auth}`, { status: true }).subscribe(
              res => {
                this.spinnerService.hide();
                this.toastr.successToastr("You're Sucesfully Connected With " + this.domChanges.paramsObj.name);
                this.route.navigate(['/dashboard/connections/direct']);
                this.domChanges.commaEmails = '';
                this.readSentInvitations();
                this.readMyRequests();
                this.readMyConnections();
              })

          } else {
            if (confirm('Do you want to add ' + this.domChanges.paramsObj.email + ' as Secondary Email Id ?')) {
              this.http.postDataToserver(this._baseURL + '/Accounts/' + `${this.id}/userEmails?access_token=${this.auth}`, { email: this.domChanges.paramsObj.email }).subscribe(
                res => {
                  this.route.navigate(['/dashboard/connections/direct']);
                  this.toastr.successToastr('Email Added Successfully');
                })
            } else {
              this.route.navigate(['/dashboard/connections/direct']);
            }
          }
        }, 2000);
      }
    });
    if (this.auth) {
      this.readSentInvitations();
      this.readMyConnections();
      this.readMyRequests();
    } else {
      this.spinnerService.hide();
      this.route.navigate(['/login/direct']);
    }
  }

  readSentInvitations() {
    this.spinnerService.show();
    this.http.getDataFromServer(this._baseURL + '/invitedMails/notAccepted' + `?id=${this.id}&access_token=${this.auth}`).subscribe(
      res => {
        this.spinnerService.hide();
        this.sentInvitationList = [];
        if (res.data.length) {
          this.sentInvitationList = res.data;
        }
      })
  }
  readMyConnections() {
    this.spinnerService.show();
    this.http.getDataFromServer(this._baseURL + '/invitedMails/accepted' + `?id=${this.id}&access_token=${this.auth}`).subscribe(
      res => {
        this.spinnerService.hide();
        this.myConncetionsList = [];
        if (res.data.length) {
          this.myConncetionsList = res.data;
        }
      })
  }
  readMyRequests() {
    this.spinnerService.show();
    this.http.getDataFromServer(this._baseURL + '/invitedMails/getInvites' + `?id=${this.id}&access_token=${this.auth}`).subscribe(
      res => {
        this.spinnerService.hide();
        this.requestToMeList = [];
        if (res.data.length) {
          this.requestToMeList = res.data;
        }
      })
  }

  sendEmailInvitaions() {
    if (this.domChanges.commaEmails) {
      let emailsList = this.domChanges.commaEmails.split(',');
      var _invitedMails: any = [];
      for (let key = 0; key < emailsList.length; key++) {
        _invitedMails.push({ email: emailsList[key] });
      }
      this.spinnerService.show();
      var count = 0;
      for (var k = 0; k < _invitedMails.length; k++) {
        count = k;
        this.http.postDataToserver(this._baseURL + '/invitedMails' + `?access_token=${this.auth}`, _invitedMails[k]).subscribe(
          res => {
            this.spinnerService.hide();
            if (count == _invitedMails.length - 1) {
              this.toastr.successToastr('Invitation Sent Successfully');
              this.domChanges.commaEmails = '';
              this.readSentInvitations();
            }
          })
      }
    } else {
      this.toastr.errorToastr('Enter Comma separated Emails');
    }
  }
  resendInvitation(item: any) {
    if (confirm('Do you want to send invitation for  ' + item.email)) {
      this.spinnerService.show();
      this.http.postDataToserver(this._baseURL + '/invitedMails/resendInvite' + `?access_token=${this.auth}`, { id: item.id }).subscribe(
        res => {
          this.spinnerService.hide();
          this.toastr.successToastr('Invitation Sent Successfully');
          this.domChanges.commaEmails = '';
          this.readSentInvitations();
        })
    }
  }
  acceptOrRejectInv(item: any, type: any) {
    if (type == 'accept') {
      this.spinnerService.show();
      this.http.updateDataToServer(this._baseURL + '/invitedMails/' + `${item.id}?access_token=${this.auth}`, { status: true }).subscribe(
        res => {
          this.spinnerService.hide();
          this.toastr.successToastr('Invitation Accepted Successfully');
          this.domChanges.commaEmails = '';
          this.readSentInvitations();
          this.readMyRequests();
          this.readMyConnections();
        })

    } else {
      this.spinnerService.show();
      this.http.deleteDataFromServer(this._baseURL + '/invitedMails/' + `${item.id}?access_token=${this.auth}`).subscribe(
        res => {
          this.spinnerService.hide();
          this.toastr.successToastr('Invitation Rejected Successfully');
          this.domChanges.commaEmails = '';
          this.readSentInvitations();
          this.readMyRequests();
          this.readMyConnections();
        })

    }
  }
  viewProfile(item: any) {
    this.spinnerService.show();
    item.sent ? this.route.navigate(['', item.accepted.username]) : this.route.navigate(["", item.account.username])

  }

}
