<div class="page-body">
  <div class="card">
    <div class="card-header">
      <div class="job-details-list">
        <p *ngIf="!domChanges.cmpDesc" [innerHTML]="companyMdl.cmpDesc | safeHtml" (click)="domChanges.cmpDesc=true"></p>
        <angular-editor *ngIf="domChanges.cmpDesc" [(ngModel)]="companyMdl.cmpDesc" [config]="config"></angular-editor>
        <div class="text-right" *ngIf="domChanges.cmpDesc">
          <br>
          <a class="btn btn-primary waves-effect waves-light m-r-20" (click)="saveorUpdateCmpDesc(companyMdl)">Save</a>
          <a id="edit-cancel" class="btn btn-default waves-effect" (click)="domChanges.cmpDesc=false">Cancel</a>
        </div>
      </div>
    </div>
    <div class="card-header">
      <h4 class="sub-title">Areas of Expertise
        <a class="f-right" (click)="domChanges.areaOfExpClicked=true"> Add </a></h4>
      <div class="job-details-list" *ngIf="domChanges.areaOfExpClicked">
        <form class="md-float-material" (ngSubmit)="cmpyFrm.form.valid && addorUpdateArea()" #cmpyFrm="ngForm"
          novalidate>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Area Of Expertise</label>
            <div class="col-sm-10">
              <input type="text" name="name" class="form-control" placeholder="Area Of Expertise" [(ngModel)]="areaOfExpMdl.name"
                #name="ngModel" [ngClass]="{ 'is-invalid': cmpyFrm.submitted && name.invalid }" required>
              <div *ngIf="cmpyFrm.submitted && name.invalid" class="invalid-feedback text-left">
                <div *ngIf="name.errors.required">Area Of Expertise is required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Upload File</label>
            <div class="col-sm-10">
              <input type="file" name="image" class="form-control" (change)="onFileChanged($event,'area')">
            </div>
          </div>
          <div class="form-group row" *ngIf="filePreview">
            <label class="col-sm-2 col-form-label">Image Preview</label>
            <div class="col-sm-10">
              <img style="width:140px;height:140px" [src]="filePreview | safeImgUrl" />
            </div>
          </div>
          <div class="text-right" *ngIf="domChanges.areaOfExpClicked">
            <br>
            <button type="submit" class="btn btn-primary waves-effect waves-light m-r-20">{{domChanges.areaOfExpEdit
              ? 'Update':'Save'}}
            </button>
            <a id="edit-cancel" class="btn btn-default waves-effect" (click)="domChanges.areaOfExpClicked=false;resetDOM();">Cancel</a>
          </div>
        </form>
      </div>
      <div class="row users-card" *ngIf="!domChanges.areaOfExpClicked">
        <div class="col-lg-6 col-xl-3 col-md-6" *ngFor="let item of areaOfExpList;let i =index">
          <div class="card rounded-card user-card" >
            <div class="card-block">
              <div class="img-hover" (click)="editAreaOfExp(item)">
                <img *ngIf="item.image" class="img-radius" [src]="item.image | safeImgUrl" alt="bg-img">
                <img *ngIf="!item.image" class="img-radius" src="assets/images/noImage.png" alt="bg-img">
                <div class="img-overlay img-radius">
                </div>
              </div>
              <div class="user-content">
                <p class="" (click)="editAreaOfExp(item)">{{item.name}}
                  &nbsp;&nbsp;<i class="fa fa-trash-o f-right" style="color:rgb(235, 66, 0)" (click)="deleteareaOfExp(item,i)"
                    placement="top" ngbTooltip="Delete ?"></i></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-header">
      <h4 class="sub-title">Management Team
        <a class="f-right" (click)="domChanges.managementClicked=true"> Add </a></h4>
      <div class="job-details-list" *ngIf="domChanges.managementClicked">
        <form class="md-float-material" (ngSubmit)="mngFrm.form.valid && addorUpdateTeam()" #mngFrm="ngForm" novalidate>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Name*</label>
            <div class="col-sm-10">
              <input type="text" name="fname" class="form-control" placeholder="Name" [(ngModel)]="teamMdl.name" #fname="ngModel"
                [ngClass]="{ 'is-invalid': mngFrm.submitted && fname.invalid }" required>
              <div *ngIf="mngFrm.submitted && fname.invalid" class="invalid-feedback text-left">
                <div *ngIf="fname.errors.required">Name is required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-10">
              <input type="text" name="email" class="form-control" placeholder="Email" [(ngModel)]="teamMdl.email"
                #email="ngModel" [ngClass]="{ 'is-invalid': mngFrm.submitted && email.invalid }">
              <div *ngIf="mngFrm.submitted && email.invalid" class="invalid-feedback text-left">
                <div *ngIf="email.errors.required">Email required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Designation</label>
            <div class="col-sm-10">
              <input type="text" name="designation" class="form-control" placeholder="Designation" [(ngModel)]="teamMdl.designation"
                #designation="ngModel" [ngClass]="{ 'is-invalid': mngFrm.submitted && designation.invalid }">
              <div *ngIf="mngFrm.submitted && designation.invalid" class="invalid-feedback text-left">
                <div *ngIf="designation.errors.required">Designation is required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Upload File</label>
            <div class="col-sm-10">
              <input type="file" name="image" class="form-control" (change)="onFileChanged($event,'team')"
                [ngClass]="{ 'is-invalid': mngFrm.submitted && image.invalid }">
              <div *ngIf="mngFrm.submitted && image.invalid" class="invalid-feedback text-left">
                <div *ngIf="image.errors.required">Image is required</div>
              </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="filePreview">
            <label class="col-sm-2 col-form-label">Image Preview</label>
            <div class="col-sm-10">
              <img style="width:140px;height:140px" [src]="filePreview | safeImgUrl" />
            </div>
          </div>
          <div class="text-right" *ngIf="domChanges.managementClicked">
            <br>
            <button type="submit" class="btn btn-primary waves-effect waves-light m-r-20">{{domChanges.teamEdit
              ? 'Update':'Save'}}
            </button>
            <a id="edit-cancel" class="btn btn-default waves-effect" (click)="domChanges.managementClicked=false;resetDOM()">Cancel</a>
          </div>
        </form>
      </div>
      <div class="row users-card" *ngIf="!domChanges.managementClicked">
        <div class="col-lg-6 col-xl-3 col-md-6" *ngFor="let item of teamList;let i = index">
          <div class="card rounded-card user-card">
            <div class="card-block">
              <div class="img-hover" (click)="editAreaOfTeam(item)">
                <img *ngIf="item.image" class="img-radius" [src]="item.image | safeImgUrl" alt="bg-img">
                <img *ngIf="!item.image" class="img-radius" src="assets/images/noImage.png" alt="bg-img">
                <div class="img-overlay img-radius">
                </div>
              </div>
              <div class="user-content" (click)="editAreaOfTeam(item)">
                <h4 class="">{{item.name}}  &nbsp;&nbsp;<i class="fa fa-trash-o f-right" style="color:rgb(235, 66, 0)" (click)="deleteTeam(item,i)"
                  placement="top" ngbTooltip="Delete ?"></i></h4>
                <p class="m-b-0 text-muted">{{item.email ? item.email:''}}</p>
                <p class="m-b-0 text-muted">{{item.designation ? item.designation:''}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>