import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { HttpIntercepterService } from 'src/app/shared/services/http-intercepter.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from 'src/environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-admin-skll-sets',
  templateUrl: './admin-skll-sets.component.html',
  styleUrls: ['./admin-skll-sets.component.scss']
})
export class AdminSkllSetsComponent implements OnInit {
  dtTrigger: any = new Subject();
  tdtTrigger: any = new Subject();
  dtOptions: {
    pagingType: string,
    paging: boolean
  };
  mergedList: any = [];
  constructor(private http: HttpIntercepterService, private spinnerService: Ng4LoadingSpinnerService, private modalService: NgbModal, public toastr: ToastrManager) { }
  domChanges: any = {};
  skillsSetMdl: any = {};
  private _baseURL = environment.url;
  skillSetList: any = [];
  private auth = localStorage.getItem('myToken');
  private id = localStorage.getItem('id');
  ngOnInit() {
    this.domChanges.selectedItem = [];
    this.domChanges.selectedItemList = [];
    this.dtOptions = {
      pagingType: 'full_numbers',
      paging: false
    };
    this.getSkills();
  }

  getSkills() {
    this.spinnerService.show();
    this.http.getDataFromServer(this._baseURL + '/DefinedSkillSets' + `?access_token=${this.auth}`).subscribe(
      res => {
        this.spinnerService.hide();
        if (res && res.length) {
          this.skillSetList = res;
          this.dtTrigger.next();
        } else {
          this.dtTrigger.next();
        }
      })
  }
  addOrUpdateSkill() {
    if (this.domChanges.skillEdit) {
      this.http.updateDataToServer(this._baseURL + '/DefinedSkillSets/' + `${this.skillsSetMdl.id}?access_token=${this.auth}`, { skillName: this.skillsSetMdl.skillName }).subscribe(res => {
        this.spinnerService.hide();
        this.dtTrigger.unsubscribe();
        this.skillsSetMdl = {};
        this.getSkills();
        this.domChanges.skillSetEdited = false;
        this.domChanges.skillEdit = false;
      })
    } else {
      this.http.postDataToserver(this._baseURL + '/DefinedSkillSets/' + `?access_token=${this.auth}`, this.skillsSetMdl).subscribe(res => {
        this.spinnerService.hide();
        this.dtTrigger.unsubscribe();
        this.skillsSetMdl = {};
        this.getSkills();
        this.domChanges.skillSetEdited = false;
        this.domChanges.skillEdit = false;
      })
    }
  }
  editSkill(item: any) {
    this.skillsSetMdl = item;
    this.domChanges.skillEdit = true;
    this.domChanges.skillSetEdited = true;
  }

  selectedSkill(item: any) {
    this.domChanges.isUserWantMergeSkills = false;
    if (!this.domChanges.mergeSkillName) {
      this.domChanges.mergeSkillName = item.skillName;
      this.domChanges.mergeSkillId = item.id;
    }
    for (var key in this.domChanges.selectedItem) {
      if (this.domChanges.selectedItem[key]) {
        this.domChanges.isUserWantMergeSkills = true;
      }
    }
  }
  openModel(content: any) {
    this.modalService.open(content, { centered: true });
  }
  mergeSkill() {
    this.spinnerService.show();
    this.mergedList = [];
    if (this.domChanges.mergeSkillName) {
      for (var key in this.domChanges.selectedItem) {
        if (this.domChanges.selectedItem[key]) {
          this.mergedList.push(key);
        }
      }
      this.http.postDataToserver(this._baseURL + '/DefinedSkillSets/updateAndMerge' + `?access_token=${this.auth}`, { list: this.mergedList, skillName: this.domChanges.mergeSkillName }).subscribe(res => {
        this.dtTrigger.unsubscribe();
        this.toastr.successToastr('Updated Sucessfully');
        this.spinnerService.show();
        this.domChanges.selectedItem = [];
        this.domChanges.mergeSkillName = null;
        this.domChanges.isUserWantMergeSkills = false;
        this.getSkills();
      })

    }
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
