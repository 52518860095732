<section class="login p-fixed d-flex text-center bg-primary ">
  <!-- Container-fluid starts -->
  <div class="container">
    <div class="row" *ngIf="!forgotPassword && !resetMailSent">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <form class="md-float-material" (ngSubmit)="loginFrm.form.valid && login()" #loginFrm="ngForm" novalidate>

            <div class="auth-box">
              <div class="text-center">
                <img src="assets/images/hasmanlogo.svg" alt="Hasman img">
              </div>
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-left txt-primary">Sign In</h3>
                </div>
              </div>
              <hr />
              <div class="input-group">
                <input style="line-height:2 !important" type="email" class="form-control" placeholder="Your Email Address or Username" name="email"
                  [(ngModel)]="emailOrUsername" #email="ngModel" [ngClass]="{ 'is-invalid': loginFrm.submitted && email.invalid }"
                  required>
                <div *ngIf="loginFrm.submitted && email.invalid" class="invalid-feedback text-left">
                  <div *ngIf="email.errors.required">Email or Username is required</div>
                </div>
              </div>
              <div class="input-group has-feedback">
                <input type="{{domChanges.passwordType}}" class="form-control password-eye-input" name="password" placeholder="Password" [(ngModel)]="model.password"
                  #password="ngModel" [ngClass]="{ 'is-invalid': loginFrm.submitted && password.invalid }" required
                  minlength="2" />
                <span class="input-group-addon" (click)="showHidePassword()"><i class="{{domChanges.passwordIcon}} password-eye-icon"></i></span>
                <div *ngIf="loginFrm.submitted && password.invalid" class="invalid-feedback text-left">
                  <div *ngIf="password.errors.required">Password is required</div>
                  <div *ngIf="password.errors.minlength">Password must be at least 6 characters</div>
                </div>
                <!-- <span class="md-line"></span> -->
              </div>
              <div class="row m-t-25 text-left">
                <div class="col-12">
                  <div class="checkbox-fade fade-in-primary d-">
                    <label>
                        <a class="text-left f-w-600 text-inverse" (click)="resendVerificationMail=true;forgotPassword=true;forgotEmailOrUname=null">Resend Verification Mail</a>
                    </label>
                  </div>
                  <div class="forgot-phone text-right f-right">
                    <a class="text-right f-w-600 text-inverse" (click)="forgotPassword=true;forgotEmailOrUname=null"> Forgot Password ?</a>
                  </div>
                </div>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-success btn-md btn-block waves-effect text-center m-b-20">Sign
                    in</button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-10">
                  <p class="text-inverse text-center m-b-0">Not Yet a Member ? <a style="color:#9c27b0" [routerLink]="['/register/direct']">Create
                      account</a></p>
                </div>
              </div>

            </div>
          </form>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <div class="row" *ngIf="forgotPassword && !resetMailSent">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <form class="md-float-material">
            <div class="auth-box">
              <div class="text-center">
                <div class="text-center">
                  <img src="assets/images/hasmanlogo.svg" alt="Hasman img">
                </div>
              </div>
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-left txt-primary" *ngIf="!resendVerificationMail">Forgot Password</h3>
                  <h3 class="text-left txt-primary" *ngIf="resendVerificationMail">Resend Verification Mail</h3>
                </div>
              </div>
              <hr />
              <div class="input-group">
                <input type="email" class="form-control" placeholder="Your Email Address or User ID" name="forgotEmail"
                  [(ngModel)]="forgotEmailOrUname">
                <span class="md-line"></span>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12">
                  <button type="button" [disabled]="!forgotEmailOrUname" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                    (click)="sendVerificationMail()">Send Mail</button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <p class="text-inverse text-center m-b-0"> <a style="color:#9c27b0;cursor: pointer;" (click)="forgotPassword=false;resendVerificationMail=false">Back
                      to Login</a></p>
                </div>
              </div>

            </div>
          </form>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <div class="row" *ngIf="resetMailSent && !forgotPassword">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <div class="auth-box">
            <div class="text-center">
              <img src="assets/images/hasmanlogo.svg" alt="Hasman img">
            </div>
            <div class="row m-b-20">
              <div class="col-md-12" *ngIf="!resendVerificationMail">
                <h3 class="text-center txt-primary">Thank You</h3>
                <p>Your Reset Password Link has been Sucessfully Sent to Registred Mail Id</p>
                <p>Please Wait while we redirect to Login Page</p>
              </div>
              <div class="col-md-12" *ngIf="resendVerificationMail">
                <h3 class="text-center txt-primary">Thank You</h3>
                <p>Verification Mail Sucessfully Sent to Registred Mail Id</p>
                <p>Please Wait while we redirect to Login Page</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container-fluid -->
</section>