import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpIntercepterService } from 'src/app/shared/services/http-intercepter.service';
import { LoginMdl } from '../../models/login.model'
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  forgotPassword: boolean;
  resetMailSent: boolean;
  resendVerificationMail: boolean;
  constructor(private route: Router, private http: HttpIntercepterService, public toastr: ToastrManager, private spinnerService: Ng4LoadingSpinnerService, private activatedRoute: ActivatedRoute) { }
  model: any = {};
  forgotPasswordMdl: any = {};
  domChanges: any = {
    paramsObj: {}
  };
  emailOrUsername: string;
  forgotEmailOrUname: string;
  private auth = localStorage.getItem('myToken');
  private _baseURL = environment.url;
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.domChanges.params = params.ref;
      if (this.domChanges.params != "direct") {
        let paramsArr = this.domChanges.params.split(',');
        this.domChanges.paramsObj.id = paramsArr[0];
        this.domChanges.paramsObj.email = paramsArr[1];
        this.domChanges.paramsObj.name = paramsArr[2];
      }
    });
    this.spinnerService.hide();
    this.domChanges.passwordType = 'password';
    this.domChanges.passwordIcon = 'icofont icofont-eye-blocked'
  }
  login() {
    // localStorage.setItem("myToken", "qwAzxqwaSDzxqwadszx");
    // this.route.navigate(['/dashboard/profile']);
    if (this.model.email) {
      delete this.model.email;
    }
    if (this.model.username) {
      delete this.model.username;
    }
    this.spinnerService.show();
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(this.emailOrUsername) == false) {
      this.model.username = this.emailOrUsername;
    } else {
      this.model.email = this.emailOrUsername;
    }
    this.http.login('Accounts/login', this.model).subscribe(
      res => {
        this.spinnerService.hide();
        localStorage.setItem("redirect", res.redirect);
        localStorage.setItem("myToken", res.id);
        localStorage.setItem("id", res.userId);
        if (this.domChanges.params != 'direct') {
          this.route.navigate(['/dashboard/connections', this.domChanges.params]);
        } else {
          this.route.navigate(['/dashboard/profile']);
        }
      },
      error => {
        this.spinnerService.hide();
      })
  }
  sendVerificationMail() {
    this.forgotPasswordMdl = {};
    let regExp = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (regExp.test(this.forgotEmailOrUname) == false) {
      this.forgotPasswordMdl.username = this.forgotEmailOrUname;
    } else {
      this.forgotPasswordMdl.email = this.forgotEmailOrUname;
    }
    if (!this.resendVerificationMail) {
      this.http.login('Accounts/reset', this.forgotPasswordMdl).subscribe(
        res => {
          this.forgotPassword = false;
          this.resetMailSent = true;
          setTimeout(() => {
            this.forgotPassword = false;
            this.resetMailSent = false;
          }, 5000);
        })
    } else {
      this.http.login('Accounts/resendVerificationMail', this.forgotPasswordMdl).subscribe(
        res => {
          this.forgotPassword = false;
          this.resetMailSent = true;
          setTimeout(() => {
            this.forgotPassword = false;
            this.resendVerificationMail = false;
            this.resetMailSent = false;
          }, 5000);
        })
    }
  }
  showHidePassword() {
    if (this.domChanges.passwordType == 'password') {
      this.domChanges.passwordType = 'text';
      this.domChanges.passwordIcon = 'icofont icofont-eye'
    } else {
      this.domChanges.passwordType = 'password';
      this.domChanges.passwordIcon = 'icofont icofont-eye-blocked'
    }
  }
}
